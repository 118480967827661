require("dotenv").config()

export default (endpoint, method, body, params) => {
    let suffix = (endpoint == "users") & (method?.toLowerCase() =="get") & (params?.username) ? `/${params.username}`:"";
    let url = `https://trading.tricap.dk/api/${endpoint}${suffix}`;
    console.log(url)
    console.log(process.env.APIKEY)
    let request_headers = {
        url,
        method: method?.toUpperCase(),
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "4mysupersecret"
        }
    }
    if(body) request_headers.body = JSON.stringify(body)
    return fetch(url, request_headers)
}