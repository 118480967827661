import React, { useMemo,useState } from 'react';
import ReactDOM from 'react-dom';
import './output.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import Context from './store/context';
import { AuthStateProvider } from './store/auth';

const Index = () => {
  const [user, setUser] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [globalState, setGlobalState] = React.useState({});
  const value = useMemo(() => ({user, setUser, globalState, setGlobalState, notifications, setNotifications}), 
  [user, setUser, globalState, setGlobalState, notifications, setNotifications])
  
  return (
    <Context.Provider value={value}>
      <AuthStateProvider>
        <App/>
      </AuthStateProvider>
    </Context.Provider>
  )
}

ReactDOM.render(
  <Index/>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

