const globalStyle = user => {
  const text_size = user?.settings?.preferences?.text_size
  return {
    table: {
      table: {
        border: "1px solid #eee",
        borderSpacing: 0,
        padding: 5,
        margin: 3,
      },
      header: {
        justifyContent: "center",
        alignItems: "center",
      },
      header_cell: {
        fontSize: text_size ?? 13,
      },
      cell: {
        minWidth: 20,
        fontSize: text_size ?? 12,
        padding: "0 3px",
        borderTop: "1px solid rgba(222,222,222,0.8)",
        borderBottom: "1px solid rgba(222,222,222,0.8)"
      },
      algos_table_cell: {
        minWidth: 20,
        fontSize: text_size ?? 12,
        padding: "0 3px",
        borderTop: "1px solid rgba(222,222,222,0.8)",
        borderBottom: "1px solid rgba(222,222,222,0.8)"
      },
      resizableColumn: {
        borderRight: "2px solid rgba(222,222,222,0.8)"
      }
    },
    confirmation_box: {
      border: "1px solid #eee",
      padding: 10
    },
    closing_icon: {
      position: "absolute", 
      right:12, 
      top: 12,
      cursor:"pointer",
    },
    inc_dec: {
      padding: "2px 5px",
      backgroundColor: "#226699",
      cursor: "pointer",
    },
    input: {
      minWidth: 20, 
      maxWidth: 100,
      fontSize: text_size ?? 11,
      height: 8, 
      margin: 0, 
      marginTop: 0, 
      borderRadius:0, 
      padding: 2,
    },
    order_type: {
      backgroundColor: "#eee",
      color: "#000",
      fontSize: text_size ?? 11,
      cursor: "pointer",
      padding: "1px 4px",
    },
    order_type_chosen: {
      backgroundColor: "#226699", 
      color: "white",
      fontSize: text_size ?? 11,
      cursor: "pointer",
      padding: "1px 4px",
    },
    buy_chosen: {
      backgroundColor: "#226699", 
      color: "white",
    },
    sell_chosen: {
      backgroundColor: "red", 
      color: "white",
    },
    buy_sell: {
      fontSize: text_size ?? 11,
      fontWeight: "normal",
      padding: "1px 5px",
      borderRadius: 0,
      backgroundColor: "#eee", 
      color: "black",
    },
    dropdown: {
      textAlign: "center",
      marginTop: 5,
    },
    btnTrading: {
      borderRadius: 0,
      fontSize: text_size ?? 11,
      margin: 3,
      padding: "3px 5px",
    },
    tooltip: {
      backgroundColor: "rgba(155,195,255,0.9)",
      position: "absolute",
      fontSize: text_size ?? 10,
      padding: 8,
      color: "#111",
      width: 100,
      fontWeight: 100,
      border: "2px solid #226699",
      borderRadius: 25,
    },
    notifications_bell: {
      color: "#111", 
      cursor: "pointer",
      padding: "0",
      margin: "0 10px",
    }
  }
}
const styles = globalStyle()

export { styles, globalStyle }