import React, { useCallback, useContext, useEffect, useState } from 'react';
import Context from '../store/context';
import api from '../api/api';
import "../buyback.css"
import { styles } from '../global/styles';
import { Select, Input } from 'antd';
import Sidebar from '../components/sidebar';

const { Option } = Select;
const colorMap = new Map();
let colorIndex = 0;

const getReport = (report_type, report, totalNavEffectReport, pnlEffectPostMarketReport, fundPercentageNavReport, fundDividendReport, reportSettings) => {
    if (report_type === 'buyback') {
        if (reportSettings?.fund) {
            return (
                <div style={{justifyContent: "left", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                    <table border={1} style={{...classes.buyback_table, fontSize: "0.8rem"}}>
                        {report?.headers?.map((row, i) => (
                            <thead key={i} style={classes.header}>
                                <tr key={row?.[0]?.name}>
                                {row.map((header, h) => {
                                    var thClass = header?.class ?? ""
                                    if(i === 0) thClass += "sep-right"
                                    return (
                                    <th style={{borderRight: '1px solid rgba(222,222,222,0.8)', borderLeft: '1px solid rgba(222,222,222,0.8)'}} key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                                )})}
                                </tr>
                            </thead>
                        ))}
                        <tbody>
                            {report?.rows?.map((row, i) => (
                                <tr className={row?.class} style={{...row?.style}} key={i}>
                                    {row?.values?.map((val, i) => (<td style={{fontWeight: "bold", color: "black", textAlign: "right", ...styles.table.cell}} key={val?.v + i} className={val?.class}>{i !== 0 ? parseFloat(val?.v)?.toLocaleString() : val?.v}</td>))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <div style={{justifyContent: "left", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                    <table border={1} style={{...classes.buyback_table, fontSize: "0.8rem"}}>
                        {report?.headers?.map((row, i) => (
                            <thead key={i} style={classes.header}>
                                <tr key={row?.[0]?.name}>
                                {row.map((header, h) => {
                                    var thClass = header?.class ?? ""
                                    if(i === 0) thClass += "sep-right"
                                    return (
                                    <th style={{borderRight: '1px solid rgba(222,222,222,0.8)', borderLeft: '1px solid rgba(222,222,222,0.8)'}} key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                                )})}
                                </tr>
                            </thead>
                        ))}
                        <tbody>
                            {report?.rows?.map((row, i) => (
                                <tr className={row?.class} style={row?.style} key={i}>
                                    {row?.values?.map((val, i) => (<td style={{...styles.table.cell}} key={val?.v + i} className={val?.class}>{val?.v}</td>))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        }
    } else if (report_type === 'financial_reports' || report_type === 'holidays') {
        return (
            <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                <table border={1} style={{...classes.table, fontSize: "0.8rem"}}>
                    {report?.headers?.map((row, i) => (
                        <thead key={i} style={classes.header}>
                            <tr key={row?.[0]?.name}>
                            {row.map((header, h) => {
                                var thClass = header?.class ?? ""
                                if(i === 0) thClass += "sep-right"
                                return (
                                <th key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                            )})}
                            </tr>
                        </thead>
                    ))}
                    <tbody>
                        {report?.rows?.map((row, i) => (
                            <tr className={row?.class} style={{...styles.table.cell, textAlign: "center", backgroundColor: row.values[1].v === new Date().toISOString().split('T')[0] ? '#e2f0d9' : ''}} key={i}>
                                {row?.values?.map((val, i) => (<td style={{color: (val.v === 'yes' && row?.values[i+1].v === 'No Trading') || row?.values[i].v === 'No Trading' ? 'red' : (val.v === 'yes' && row?.values[i+1].v === 'Partial Trading Day') || row?.values[i].v === 'Partial Trading Day' ? 'green': (val.v === 'yes' && row?.values[i+1].v === 'Full Trading Day') || row?.values[i].v === 'Full Trading Day' ? 'green': ''}} key={val?.v + i} className={val?.class}>{val?.v}</td>))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    } else if (report_type === 'post_market_effect') {  
        return (
            <div>
                <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                    <table border={1} style={{...classes.table, fontSize: "0.8rem"}}>
                        {report?.headers?.map((row, i) => (
                            <thead key={i} style={classes.header}>
                                <tr key={row?.[0]?.name}>
                                {row.map((header, h) => {
                                    var thClass = header?.class ?? ""
                                    if(i === 0) thClass += "sep-right"
                                    return (
                                    <th key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                                )})}
                                </tr>
                            </thead>
                        ))}
                        <tbody>
                            {report?.rows?.map((row, i) => {
                                return (
                                <tr className={row?.class} style={{textAlign: "center"}} key={i}>
                                    {row?.values?.map((val, i) => {
                                        let color = colorMap.get(val.v);
                                        if (!color) {
                                            color = colors[colorIndex % colors.length];
                                            colorMap.set(val.v, color);
                                            colorIndex++;
                                        }

                                    return (
                                        <td key={val?.v + i} style={{...styles.table.cell, backgroundColor: i === 0 && report?.rows?.filter(row => row?.values[0]?.v === val.v).length > 1 ? color: ''}} className={val?.class}>
                                        {val?.v}
                                        </td>
                                    );
                                    })}
                                </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                    <table border={1} style={{...classes.pnl_effect_post_market_table, fontSize: "0.8rem"}}>
                        {pnlEffectPostMarketReport?.headers?.map((row, i) => (
                            <thead key={i} style={classes.header}>
                                <tr key={row?.[0]?.name}>
                                {row.map((header, h) => {
                                    var thClass = header?.class ?? ""
                                    if(i === 0) thClass += "sep-right"
                                    return (
                                    <th key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                                )})}
                                </tr>
                            </thead>
                        ))}
                        <tbody>
                            {pnlEffectPostMarketReport?.rows?.map((row, i) => (
                                <tr className={row?.class} style={{textAlign: "center"}} key={i}>
                                    {row?.values?.map((val, i) => (<td styles={{...styles.table.cell}} key={val?.v + i} className={val?.class}>{val?.v}</td>))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                    <table border={1} style={{...classes.total_nav_effect_table, fontSize: "0.8rem"}}>
                        {totalNavEffectReport?.headers?.map((row, i) => (
                            <thead key={i} style={classes.header}>
                                <tr key={row?.[0]?.name}>
                                {row.map((header, h) => {
                                    var thClass = header?.class ?? ""
                                    if(i === 0) thClass += "sep-right"
                                    return (
                                    <th key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                                )})}
                                </tr>
                            </thead>
                        ))}
                        <tbody>
                            {totalNavEffectReport?.rows?.map((row, i) => (
                                <tr className={row?.class} style={{textAlign: "center"}} key={i}>
                                    {row?.values?.map((val, i) => (<td styles={{...styles.table.cell}} key={val?.v + i} className={val?.class}>{val?.v}</td>))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    } else if (report_type === 'portfolio_factsheet_dates') {
        return (
            <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                <table border={1} style={{...classes.table, fontSize: "0.8rem"}}>
                    {report?.headers?.map((row, i) => (
                        <thead key={i} style={classes.header}>
                            <tr key={row?.[0]?.name}>
                            {row.map((header, h) => {
                                var thClass = header?.class ?? ""
                                if(i === 0) thClass += "sep-right"
                                return (
                                <th key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                            )})}
                            </tr>
                        </thead>
                    ))}
                    <tbody>
                        {report?.rows?.map((row, i) => (
                            <tr className={row?.class} style={{textAlign: "center"}} key={i}>
                                {row?.values?.map((val, i) => (<td style={{...styles.table.cell, color: (i === 0 && row?.values[i+1].v != row?.values[i+2].v) ? 'red' : ''}} key={val?.v + i} className={val?.class}>{val?.v}</td>))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    } else if (report_type === 'terms_ig' || report_type === 'terms_saxo') {
        return (
            <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                <table border={1} style={{...classes.table, fontSize: "0.8rem"}}>
                    {report?.headers?.map((row, i) => (
                        <thead key={i} style={classes.header}>
                            <tr key={row?.[0]?.name}>
                            {row.map((header, h) => {
                                var thClass = header?.class ?? ""
                                if(i === 0) thClass += "sep-right"
                                return (
                                <th key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                            )})}
                            </tr>
                        </thead>
                    ))}
                    <tbody>
                        {report?.rows?.map((row, i) => (
                            <tr className={row?.class} style={{textAlign: "center"}} key={i}>
                                {row?.values?.map((val, i) => (<td style={{...styles.table.cell, color: (i === 0 && row?.values[row?.values.length-1].v == "1") ? 'red' : (i == 1 && row?.values[i].v.toLowerCase() != "yes") ? 'red': (i === 2 && parseFloat(row?.values[i].v.substring(0, row?.values[i].v.length-1)) >= 1.0) ? 'red' : ''}} key={val?.v + i} className={val?.class}>{val?.v}</td>))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    } else if (report_type === 'cash_dividends_report' || report_type === 'rest_dividends_report') {
        return (
            <div>
                <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                    <table border={1} style={{...classes.table, fontSize: "0.8rem"}}>
                        {report?.headers?.map((row, i) => (
                            <thead key={i} style={classes.header}>
                                <tr key={row?.[0]?.name}>
                                {row.map((header, h) => {
                                    var thClass = header?.class ?? ""
                                    if(i === 0) thClass += "sep-right"
                                    return (
                                    <th key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                                )})}
                                </tr>
                            </thead>
                        ))}
                        <tbody>
                            {report?.rows?.map((row, i) => {
                            return (
                                <tr className={row?.class} style={{textAlign: "center", backgroundColor: row.values[1].v === new Date().toISOString().split('T')[0] ? '#e2f0d9' : row.values[1].v === new Date(Date.now() - 86400000).toISOString().split('T')[0] ? '#D3D3D3': ''}} key={i}>
                                    {row?.values?.map((val, i) => (<td styles={{...styles.table.cell}} key={val?.v + i} className={val?.class}>{val?.v}</td>))}
                                </tr>
                            )})}
                        </tbody>
                    </table>
                </div>
                {reportSettings?.ticker ? (
                    <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                        <table border={1} style={{...classes.fund_percentage_nav_table, fontSize: "0.8rem"}}>
                            {fundPercentageNavReport?.headers?.map((row, i) => (
                                <thead key={i} style={classes.header}>
                                    <tr key={row?.[0]?.name}>
                                    {row.map((header, h) => {
                                        var thClass = header?.class ?? ""
                                        if(i === 0) thClass += "sep-right"
                                        return (
                                        <th key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                                    )})}
                                    </tr>
                                </thead>
                            ))}
                            <tbody>
                                {fundPercentageNavReport?.rows?.map((row, i) => (
                                    <tr className={row?.class}style={{textAlign: "center"}} key={i}>
                                        {row?.values?.map((val, i) => (<td styles={{...styles.table.cell}} key={val?.v + i} className={val?.class}>{val?.v}</td>))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ): null}
                {(!reportSettings?.ticker || !reportSettings?.date) ? (
                    <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                        <table border={1} style={{...classes.fund_percentage_nav_table, fontSize: "0.8rem"}}>
                            {fundDividendReport?.headers?.map((row, i) => (
                                <thead key={i} style={classes.header}>
                                    <tr key={row?.[0]?.name}>
                                    {row.map((header, h) => {
                                        var thClass = header?.class ?? ""
                                        if(i === 0) thClass += "sep-right"
                                        return (
                                        <th key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                                    )})}
                                    </tr>
                                </thead>
                            ))}
                            <tbody>
                                {fundDividendReport?.rows?.map((row, i) => (
                                    <tr className={row?.class} style={{textAlign: "center", backgroundColor: row.values[3].v === new Date().toISOString().split('T')[0] ? '#e2f0d9' : ''}} key={i}>
                                        {row?.values?.map((val, i) => (<td styles={{...styles.table.cell}} key={val?.v + i} className={val?.class}>{val?.v}</td>))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ): null}
            </div>
        );
    } else {
        return null;
    }
}

const Reports = () => {
    const { user, setUser, globalState} = useContext(Context);
    const [tickers, setTickers] = useState([]);
    const [funds, setFunds] = useState([]);
    const [countries, setCountries] = useState([]);
    const [report, setReport] = useState({});
    const [totalNavEffectReport, setTotalNavEffectReport] = useState({});
    const [pnlEffectPostMarketReport, setPnlEffectPostMarketReport] = useState({});
    const [fundPercentageNavReport, setFundPercentageNavReport] = useState({});
    const [fundDividendReport, setFundDividendReport] = useState({});
    const [reportSettings, setReportSettings] = useState({
        report_type: "holidays"
    });

    // Reset fund dividend report.
    useEffect(() => {
        setFundDividendReport({});
    }, [report]);

    useEffect(() => {
        async function getTickers() {
            await api("fund-data/portfolio-securities").then(res => res.json()).then(data => {
                setTickers(data?.data);
            });    
        }
        getTickers()
    }, [])

    useEffect(() => {
        async function getFunds() {
            await api("fund-data/all-funds").then(res => res.json()).then(data => {
                setFunds(data?.data);
            });    
        }
        getFunds()
    }, [])

    useEffect(() => {
        async function getCountries() {
            await api("all-countries").then(res => res.json()).then(data => {
                setCountries(data?.data);
            });    
        }
        getCountries()
    }, [])

    useEffect(() => {
        async function getLastUpdate() {
            await api("reports-last-updated").then(res => res.json()).then(data => {
                setReportSettings({...reportSettings, last_updated: data[0]?.data});
            });    
        }
        getLastUpdate()
    }, [])

    useEffect(() => {
        async function getReport() {
            if (reportSettings?.report_type === 'buyback') {
                if (reportSettings?.fund) {
                    await api(`buyback-report?fund=${reportSettings?.fund}`).then(res => res.json()).then(data => {setReport(data)});
                } else {
                    await api("buyback-report").then(res => res.json()).then(data => {setReport(data)});
                }
            } else if (reportSettings?.report_type === 'financial_reports') {
                if (reportSettings?.fund) {
                    await api(`financial-reports?fund=${reportSettings?.fund}`).then(res => res.json()).then(data => {setReport(data)});
                } else if (reportSettings?.ticker) {
                    await api(`financial-reports?ticker=${reportSettings?.ticker}`).then(res => res.json()).then(data => {setReport(data)});
                } else if (reportSettings?.date) {
                    await api(`financial-reports?date=${reportSettings?.date}`).then(res => res.json()).then(data => {setReport(data)});
                } else {
                    await api("financial-reports").then(res => res.json()).then(data => {setReport(data)});
                }
            } else if (reportSettings?.report_type === 'post_market_effect') {
                await api("post-market-effect-report").then(res => res.json()).then(data => {setReport(data)});
                await api("total-nav-effect-report").then(res => res.json()).then(data => {setTotalNavEffectReport(data)});
                await api("pnl-effect-post-market-report").then(res => res.json()).then(data => {setPnlEffectPostMarketReport(data)});
            } else if (reportSettings?.report_type === 'holidays') {
                if (reportSettings?.country) {
                    await api(`country-holidays-report?country=${reportSettings?.country}`).then(res => res.json()).then(data => {setReport(data)})
                } else {
                    await api("holidays-report").then(res => res.json()).then(data => {setReport(data)})
                }
            } else if (reportSettings?.report_type === 'portfolio_factsheet_dates') {
                await api("portfolio-factsheet-dates-report").then(res => res.json()).then(data => {setReport(data)})
            } else if (reportSettings?.report_type === 'terms_ig') {
                await api("ig-terms-report").then(res => res.json()).then(data => {setReport(data)})
            } else if (reportSettings?.report_type === 'terms_saxo') {
                await api("saxo-terms-report").then(res => res.json()).then(data => {setReport(data)})
            } else if (reportSettings?.report_type === 'cash_dividends_report') {
                if (reportSettings?.fund) {
                    await api(`cash-dividends-report?fund=${reportSettings?.fund}`).then(res => res.json()).then(data => {setReport(data)});
                    await api(`fund-dividends-report?fund=${reportSettings?.fund}`).then(res => res.json()).then(data => {setFundDividendReport(data)});
                } else if (reportSettings?.ticker) {
                    await api(`cash-dividends-report?ticker=${reportSettings?.ticker}`).then(res => res.json()).then(data => {setReport(data)});
                    await api(`fund-percentage-nav?ticker=${reportSettings?.ticker}`).then(res => res.json()).then(data => {setFundPercentageNavReport(data)});
                } else if (reportSettings?.date) {
                    await api(`cash-dividends-report?date=${reportSettings?.date}`).then(res => res.json()).then(data => {setReport(data)});
                } else {
                    await api("cash-dividends-report").then(res => res.json()).then(data => {setReport(data)});
                    await api(`fund-dividends-report`).then(res => res.json()).then(data => {setFundDividendReport(data)});
                }
            } else if (reportSettings?.report_type === 'rest_dividends_report') {
                if (reportSettings?.fund) {
                    await api(`rest-dividends-report?fund=${reportSettings?.fund}`).then(res => res.json()).then(data => {setReport(data)});
                } else if (reportSettings?.ticker) {
                    await api(`rest-dividends-report?ticker=${reportSettings?.ticker}`).then(res => res.json()).then(data => {setReport(data)});
                    await api(`fund-percentage-nav?ticker=${reportSettings?.ticker}`).then(res => res.json()).then(data => {setFundPercentageNavReport(data)});
                } else if (reportSettings?.date) {
                    await api(`rest-dividends-report?date=${reportSettings?.date}`).then(res => res.json()).then(data => {setReport(data)});
                } else {
                    await api("rest-dividends-report").then(res => res.json()).then(data => {setReport(data)});
                }
            }
        }
        if (reportSettings?.report_type) {
            getReport()
        }
    }, [reportSettings])

    return (
        <main style={{height: "100%", boxSizing: "inherit"}}>
            {getReport(reportSettings?.report_type, report, totalNavEffectReport, pnlEffectPostMarketReport, fundPercentageNavReport, fundDividendReport, reportSettings)}
            <Sidebar>
                <div style={{justifyContent: "space-around", margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:100, paddingBottom:20}}>
                    {reportSettings?.report_type === 'financial_reports' || reportSettings?.report_type === 'post_market_effect' ? (
                        <p style={{paddingBottom: 20}}>Last Updated: {new Date(reportSettings?.last_updated).toLocaleString()}</p>
                    ): null}
                    <h3 style={{paddingBottom:5}}>Report Type:</h3>
                    <Select
                        style={{ width: 200, paddingBottom:15}}
                        placeholder="Select a report"
                        optionFilterProp="ticker"
                        defaultValue="holidays"
                        value={reportSettings?.report_type}
                        onChange={(value) => {
                            setReportSettings({...reportSettings, report_type: value, fund: null, date: null, ticker: null, country: null})
                        }}
                        filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                            <Option value="buyback">Buyback</Option>
                            <Option value="financial_reports">Financial Reports</Option>
                            <Option value="post_market_effect">Post-Market Effect</Option>
                            <Option value="holidays">Holidays</Option>
                            <Option value="portfolio_factsheet_dates">Portfolio and Factsheet Dates</Option>
                            <Option value="terms_ig">Terms IG</Option>
                            <Option value="terms_saxo">Terms Saxo</Option>
                            <Option value="cash_dividends_report">Cash Dividends</Option>
                            <Option value="rest_dividends_report">Rest Dividends</Option>
                    </Select>
                    {reportSettings?.report_type === 'financial_reports' || reportSettings?.report_type === 'cash_dividends_report' || reportSettings?.report_type === 'rest_dividends_report' ? (
                        <div>
                            <p style={{paddingBottom:5}}>Funds:</p>
                            <Select
                                showSearch
                                style={{ width: 200, paddingBottom:15}}
                                placeholder="Select a fund"
                                optionFilterProp="fund"
                                value={reportSettings?.fund}
                                onChange={value => setReportSettings({...reportSettings, fund: value, date: null, ticker: null})}
                                filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {funds.map((fundOption) => (
                                    <Option value={fundOption}>{fundOption}</Option>
                                ))}
                            </Select>
                            <p style={{paddingBottom:5}}>Portfolio Security Tickers:</p>
                            <Select
                                showSearch
                                style={{ width: 200 , paddingBottom:15}}
                                placeholder="Select a ticker"
                                value={reportSettings?.ticker}
                                optionFilterProp="ticker"
                                onChange={value => setReportSettings({...reportSettings, ticker: value, date: null, fund: null})}
                                filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {tickers.map((tickerOption) => (
                                    <Option value={tickerOption}>{tickerOption}</Option>
                                ))}
                            </Select>
                            <p style={{paddingBottom:5}}>Date:</p>
                            <input value={reportSettings?.date} style={{...styles.input, padding: 10, fontSize: 12, maxWidth: 200}} type="date" onChange={e => setReportSettings({...reportSettings, date: e?.target?.value, fund: null, ticker: null})}/>
                        </div>
                    ): null}
                    {reportSettings?.report_type === 'buyback' ? (
                        <div>
                            <p style={{paddingBottom:5}}>Funds:</p>
                            <Select
                                showSearch
                                style={{ width: 200, paddingBottom:15}}
                                placeholder="Select a fund"
                                optionFilterProp="fund"
                                value={reportSettings?.fund}
                                onChange={value => setReportSettings({...reportSettings, fund: value, date: null, ticker: null})}
                                filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {funds.map((fundOption) => (
                                    <Option value={fundOption}>{fundOption}</Option>
                                ))}
                            </Select>
                        </div>
                    ): null}
                    {reportSettings?.report_type === 'post_market_effect' ? (
                        <div>
                            <p style={{paddingBottom:5, paddingTop:15}}>NAV Filter (%):</p>
                            <Input defaultValue="0.05" type="text" value={reportSettings?.navfilter} onChange={e => {
                                if (/^\d*\.?\d*$/.test(e?.target?.value)) {
                                    setReportSettings({...reportSettings, ticker: null, date: null, fund: null, navfilter: e?.target?.value});
                                } else {
                                    alert('Please enter a valid float number');
                                }
                            }} /><br/>
                            <button onClick={() => {
                                async function getPostMarketReport() {
                                    await api(`post-market-effect-report?navfilter=${reportSettings?.navfilter}`).then(res => res.json()).then(data => {setReport(data)});
                                    await api(`pnl-effect-post-market-report?navfilter=${reportSettings?.navfilter}`).then(res => res.json()).then(data => {setPnlEffectPostMarketReport(data)});
                                }
                                getPostMarketReport()
                            }}>Update NAV Filter</button>
                        </div>
                    ): null}
                    {reportSettings?.report_type === 'holidays' ? (
                        <div>
                            <p style={{paddingBottom:5}}>Countries:</p>
                            <Select
                                showSearch
                                style={{ width: 200, paddingBottom:15}}
                                placeholder="Select a country"
                                optionFilterProp="country"
                                value={reportSettings?.country}
                                onChange={value => setReportSettings({...reportSettings, fund: null, date: null, ticker: null, country: value})}
                                filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {countries.map((countryOption) => (
                                    <Option value={countryOption}>{countryOption}</Option>
                                ))}
                            </Select>
                        </div>
                    ): null}
                    <button style={{marginTop: 50, maxWidth: 150}} onClick={() => {
                        setReportSettings({...reportSettings, fund: null, date: null, ticker: null, country: null})
                    }}>Reset Settings</button>
                </div>
            </Sidebar>
        </main>
    );
}

const classes = {
    box: {
        backgroundColor: "white",
        position:"fixed",
        padding: 10,
        borderRadius: 25,
        zIndex: 999,
    },
    closing_icon: {
        position: "absolute", 
        right:12, 
        top: 12,
        cursor:"pointer",
    },
    buyback_table: {
        borderSpacing: 0,
        backgroundColor: "white",
        color: "rgba(111,111,111,0.8)",
    },
    table: {
        borderSpacing: 0,
        backgroundColor: "white",
        color: "rgba(1,1,1,1.0)",
        minWidth: 800
    },
    total_nav_effect_table: {
        borderSpacing: 0,
        backgroundColor: "white",
        color: "rgba(1,1,1,1.0)",
        minWidth: 200
    },
    pnl_effect_post_market_table: {
        borderSpacing: 0,
        backgroundColor: "white",
        color: "rgba(1,1,1,1.0)",
        minWidth: 400
    },
    fund_percentage_nav_table: {
        borderSpacing: 0,
        backgroundColor: "white",
        color: "rgba(1,1,1,1.0)",
        minWidth: 400
    },
    header: {
        backgroundColor: "rgb(32,56,100)",
        color: "white",
        padding: "3px 5px",
        textAlign: "center",
        borderBottom: "3px solid black",
    },
    cell: {
        backgroundColor: "white",
        padding: 5,
    }
}

const colors = [
    '#ADD8E6', '#90EE90', '#AFEEEE', '#98FB98', '#E0FFFF', '#87CEFA', '#F0E68C', '#00FF7F', '#7FFFD4',
    '#40E0D0', '#00C957', '#00BFFF', '#1E90FF', '#00FFFF', '#008080', '#48D1CC', '#20B2AA', '#7FFF00',
    '#7CFC00', '#00FF00', '#32CD32', '#228B22', '#006400', '#9ACD32', '#6B8E23', '#808000', '#556B2F',
    '#66CDAA', '#8FBC8F', '#2E8B57', '#3CB371', '#00FA9A', '#00FF7F', '#2F4F4F', '#00CED1', '#66CD00',
    '#8B008B', '#8B0000', '#8B4513', '#A0522D', '#A52A2A', '#B22222', '#CD5C5C', '#DC143C', '#FF0000',
    '#FF6347', '#FF69B4', '#FF7F50', '#FF8C00', '#FFA07A', '#FFA500', '#FFC0CB', '#FFD700', '#FFFF00',
    '#FFFFE0', '#F0E68C', '#FFFACD', '#ADD8E6', '#F08080', '#E0FFFF', '#AFEEEE', '#98FB98', '#87CEFA',
    '#00FF7F', '#7FFFD4', '#40E0D0', '#00C957', '#00BFFF', '#1E90FF', '#00FFFF', '#008080', '#48D1CC',
    '#20B2AA', '#7FFF00', '#7CFC00', '#00FF00', '#32CD32', '#228B22', '#006400', '#9ACD32', '#6B8E23',
    '#808000', '#556B2F', '#66CDAA', '#8FBC8F', '#2E8B57', '#3CB371', '#00FA9A', '#00FF7F', '#2F4F4F',
    '#00CED1', '#66CD00', '#8B008B', '#8B0000', '#8B4513', '#A0522D', '#A52A2A', '#B22222', '#CD5C5C',
    '#DC143C', '#FF0000', '#FF6347', '#FF69B4', '#FF7F50', '#FF8C00']

export default Reports;