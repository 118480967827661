import React, { useContext, useEffect, useState, useRef } from 'react';
import Context from '../store/context';
import api from '../api/api';
import LoadingSpinner from '../components/loading-spinner';
import { styles } from '../global/styles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sidebar from '../components/sidebar';
import { calculateNetPositions, calculateSectorsHedgingError } from '../utils';

const Monitor = () => {
  const {user, setUser, globalState} = useContext(Context);
  const MarketdataSocket = useRef(null);
  const isMarketdataReconnecting = useRef(false);
  const isMarketdataWebSocketConnected = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const isComponentMounted = useRef(true);
  const [fundSectors, setFundSectors] = useState({});
  const [fxLastPrices, setFxLastPrices] = useState({});
  const [hedgingErrors, setHedgingErrors] = useState({});
  const hedgingErrorsRef = useRef(hedgingErrors);
  const [hedgingErrorCalculationResult, setHedgingErrorCalculationResult] = useState({});

  // IG state values.
  const [igApiKey, setIgApiKey] = useState();
  const [igCst, setIgCst] = useState();
  const [igSecurityToken, setIgSecurityToken] = useState();
  const [igUsername, setIgUsername] = useState();
  const [igPwd, setIgPwd] = useState();
  const [igAccessTokenRes, setIgAccessTokenRes] = useState();
  const [igPositions, setIgPositions] = useState({});
  const [igNetPositions, setIgNetPositions] = useState([]);
  const [reloadPositions, setReloadPositions] = useState(false);

  // Function to establish the WebSocket connection
  const connectDataWebSocket = () => {  
    const connectWebSocket = () => {
      let heartbeatInterval;
      const newSocket = new WebSocket('wss://trading.tricap.dk/api/ws/marketdata');
  
      newSocket.onopen = () => {
        isMarketdataWebSocketConnected.current = true;
        newSocket.send(JSON.stringify({
          authorization: "4mysupersecret"
        }));
        console.log('Connected to messaging WebSocket');
  
        // Send a heartbeat message at regular intervals
        heartbeatInterval = setInterval(() => {
          if (isMarketdataWebSocketConnected.current) {
            newSocket.send("heartbeat");
          } else {
            clearInterval(heartbeatInterval);
          }
        }, 3000);

        // Subscribe to relevant message channels.
        const subscriptionData = {
          "action": "subscribe",
          "topics": ["premDiscRtd"]
        }
        newSocket.send(JSON.stringify(subscriptionData));
  
        // Save marketdata connection variable.
        MarketdataSocket.current = newSocket;

        isMarketdataReconnecting.current = false;
      };
  
      newSocket.onmessage = (event) => {
        const parsedMsg = JSON.parse(event.data);
        let receivedMsg;
        if (typeof parsedMsg === 'string') {
          receivedMsg = JSON.parse(parsedMsg);
        } else {
          receivedMsg = parsedMsg
        }
        // Add most recent timestep to intraday data.
        let updatedHedgingErrors = {...hedgingErrorsRef.current}
        for (let ticker in receivedMsg?.data) {
          updatedHedgingErrors[ticker] = receivedMsg?.data?.[ticker]?.[0];
        }
        setHedgingErrors(updatedHedgingErrors);
      };
  
      newSocket.onclose = () => {
        console.log('Websocket connection closed');
        isMarketdataWebSocketConnected.current = false;
        clearInterval(heartbeatInterval);

        // Check if reconnection is already in progress
        if (!isMarketdataReconnecting.current) {
          // Attempt to reconnect every 5 seconds
          setTimeout(() => {
            if (isComponentMounted.current) {
              isMarketdataReconnecting.current = true; // Set the flag

              // Reconnect to websocket.
              connectWebSocket();
            }
          }, 5000);
        }
      };
  
      return newSocket;
    };
  
    return connectWebSocket();
  };

  // Initiate marketdata and orders websocket connection.
  useEffect(() => {
    MarketdataSocket.current = connectDataWebSocket();

    return () => {
      isComponentMounted.current = false;
      if (MarketdataSocket.current) {
        MarketdataSocket.current.close();
      }
    };
  }, []);

  // Implement a ping mechanism to keep the websocket connection alive during idle periods.
  useEffect(() => {
    const pingInterval = setInterval(() => {
      if (document.visibilityState === 'visible' && !isMarketdataWebSocketConnected.current) {
        connectDataWebSocket();
      }
    }, 30000); // Check every 30 seconds

    return () => clearInterval(pingInterval);
  }, []);

  // Load credentials from config.
  useEffect(() => {
    fetch('config.json')
      .then(response => response.json())
      .then(data => {
        setIgApiKey(data.igApiKey);
        setIgUsername(data.igUsername);
        setIgPwd(data.igPwd);
      });
  }, []);

  // Login to IG.
  useEffect(() => {
    if (igUsername && igPwd && igApiKey) {
      const data = {
        "identifier": igUsername,
        "password": igPwd
      };
      fetch(`https://api.ig.com/gateway/deal/session`, {
          "method": "POST",
          "headers": {
              "Content-Type": "application/json",
              "VERSION": 2,
              "X-IG-API-KEY": igApiKey
          },
          "body": JSON.stringify(data)
      }).then(response => {
        const headers = response.headers
        setIgCst(headers.get('CST'))
        setIgSecurityToken(headers.get('X-SECURITY-TOKEN'))
        return response.json()
      })
      .then(data => {
        setIgAccessTokenRes(data);
      })
      .catch(error => console.error(error));
    }
  }, [igUsername, igPwd, igApiKey]);

  // Get IG open positions snapshot.
  useEffect(() => {
    if ((igAccessTokenRes && igCst && igSecurityToken) || reloadPositions) {
      fetch(`https://api.ig.com/gateway/deal/positions`, {
          "method": "GET",
          "headers": {
              "VERSION": 2,
              "X-IG-API-KEY": igApiKey,
              "CST": igCst,
              "X-SECURITY-TOKEN": igSecurityToken,
          },
      }).then(response => response.json())
      .then(data => {
        const formattedPositions = data?.positions?.reduce((acc, position) => {
          acc[position?.position?.dealId] = position;
          return acc;
        }, {});

        setIgPositions(formattedPositions);
        setIgNetPositions(calculateNetPositions(Object.values(formattedPositions)))

        if (reloadPositions) {
          alert("Successfully reloaded open positions.")
        }

        setReloadPositions(false);
      })
      .catch(error => console.error(error));
    }
  }, [igAccessTokenRes, igCst, igSecurityToken, reloadPositions])

  useEffect(() => {
    async function getFundSectors() {
      await api("fund-data/sectors").then(res => res.json()).then(data => {
          setFundSectors(data?.data);
      });
    }
    getFundSectors();
  }, []);

  useEffect(() => {
    async function getFxLastPrices() {
      await api("fx-last-price").then(res => res.json()).then(data => {
          setFxLastPrices(data);
      });
    }
    getFxLastPrices();
  }, []);

  useEffect(() => {
    if (Object.keys(hedgingErrors)?.length !== 0 && igNetPositions?.length !== 0 && Object.keys(fundSectors)?.length !== 0) {
      setHedgingErrorCalculationResult(calculateSectorsHedgingError(hedgingErrors, igNetPositions, fundSectors, fxLastPrices));
    }
  }, [hedgingErrors, igNetPositions, fundSectors]);

  useEffect(() => {
    hedgingErrorsRef.current = hedgingErrors;
  }, [hedgingErrors]);
    
  return (
    <main style={{
      display: 'flex',
      height: 'calc(100vh - 40px)',
      margin: 0,
      padding: 0,
      overflow: 'hidden'
    }}>
      {isLoading && (
        <div style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <LoadingSpinner style={{ margin: 0, padding: 0 }}/>
            <button className='hover-update' style={{padding: 15, marginTop: 0, width: "5%"}} onClick={() => {
              setIsLoading(false);
            }}>Cancel</button>
        </div>)}

      <div style={{
        flex: 1,
        overflow: 'hidden',
        border: '4px solid #ccc',
        background: '#303030',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        overflow: 'auto'
      }}>

        <Tabs style={{marginTop: 30}}>
          <TabList>
            <Tab>Hedging Error</Tab>
          </TabList>
          <TabPanel>
            <button className="hover-update" style={{ marginTop: 5, fontSize: 12, display: "flex", justifyContent: "center", alignItems: 'center'}} onClick={() => {
              setReloadPositions(true);
            }}>Import Positions</button>
            <hr style={{color: "white", marginBottom: 10, borderWidth: 1, marginTop: 10}}/>
            <table style={{
              fontSize: 10,
              whiteSpace: 'nowrap',
              width: 700,
              float: 'left'
            }}>
              <thead style={styles.table.header}>
                <tr>
                  <th className="border-0 border-b-2 border-solid border-gray-300/[.45]" style={styles.table.header_cell}>Sector</th>
                  <th className="border-0 border-b-2 border-solid border-gray-300/[.45]" style={styles.table.header_cell}>Portfolio Weighted %</th>
                  <th className="border-0 border-b-2 border-solid border-gray-300/[.45]" style={styles.table.header_cell}>Portfolio Weighted DKK</th>
                  <th className="border-0 border-b-2 border-solid border-gray-300/[.45]" style={styles.table.header_cell}>All %</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(hedgingErrorCalculationResult?.sectors || {})?.map((sector, i) => (
                  <tr key={i} className="hover:bg-gray-700" style={{fontSize: 10}}>
                    {/* Sector */}
                    <td
                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                      style={{ ...styles.table.algos_table_cell, textAlign: "center", padding: 2 }}
                    >
                      {sector}
                    </td>
                    {/* Portfolio Weighted % */}
                    <td
                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                      style={{
                        ...styles.table.algos_table_cell, 
                        textAlign: 
                        "center", 
                        padding: 2
                      }}
                    >
                      {`${(hedgingErrorCalculationResult?.sectors?.[sector]?.portfolioPercentage*100)?.toFixed(2)}%`}
                    </td>
                    {/* Portfolio Weighted GBP*/}
                    <td
                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                      style={{
                        ...styles.table.algos_table_cell, 
                        textAlign: 
                        "center", 
                        padding: 2,
                        color: 
                          hedgingErrorCalculationResult?.sectors?.[sector]?.portfolio > 0 ? 'green' :
                          hedgingErrorCalculationResult?.sectors?.[sector]?.portfolio < 0 ? 'red' : ''
                      }}
                    >
                      {parseInt(hedgingErrorCalculationResult?.sectors?.[sector]?.portfolio)}
                    </td>
                    {/* Portfolio Weighted GBP*/}
                    <td
                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                      style={{
                        ...styles.table.algos_table_cell, 
                        textAlign: 
                        "center", 
                        padding: 2
                      }}
                    >
                      {`${(hedgingErrorCalculationResult?.sectors?.[sector]?.all*100)?.toFixed(2)}%`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table style={{
              fontSize: 10,
              whiteSpace: 'nowrap',
              width: 450,
              float: 'left',
              marginLeft: 50
            }}>
              <thead style={styles.table.header}>
                <tr>
                  <th className="border-0 border-b-2 border-solid border-gray-300/[.45]" style={styles.table.header_cell}>Fund</th>
                  <th className="border-0 border-b-2 border-solid border-gray-300/[.45]" style={styles.table.header_cell}>Hedging Error %</th>
                  <th className="border-0 border-b-2 border-solid border-gray-300/[.45]" style={styles.table.header_cell}>Hedging Error DKK</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(hedgingErrors || {})?.map((ticker, i) => (
                  <tr key={i} className="hover:bg-gray-700" style={{fontSize: 10}}>
                    {/* Ticker */}
                    <td
                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                      style={{ ...styles.table.algos_table_cell, textAlign: "center", padding: 2 }}
                    >
                      {ticker}
                    </td>
                    <td
                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                      style={{
                        ...styles.table.algos_table_cell, 
                        textAlign: 
                        "center", 
                        padding: 2,
                        color: 
                          hedgingErrorCalculationResult?.funds?.[ticker]?.hedgingErrorGBP > 0 ? 'green' :
                          hedgingErrorCalculationResult?.funds?.[ticker]?.hedgingErrorGBP < 0 ? 'red' : ''
                      }}
                    >
                      {`${(hedgingErrors?.[ticker]*100)?.toFixed(2)}%`}
                    </td>
                    <td
                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                      style={{
                        ...styles.table.algos_table_cell, 
                        textAlign: 
                        "center", 
                        padding: 2,
                        color: 
                          hedgingErrorCalculationResult?.funds?.[ticker]?.hedgingErrorGBP > 0 ? 'green' :
                          hedgingErrorCalculationResult?.funds?.[ticker]?.hedgingErrorGBP < 0 ? 'red' : ''
                      }}
                    >
                      {parseInt(hedgingErrorCalculationResult?.funds?.[ticker]?.hedgingErrorGBP) || 0}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </div>
    </main>
  );
}

export default Monitor;