require("dotenv").config()

export default (endpoint, method, body) => {
    let url = `https://trading.tricap.dk:8001/api/${endpoint}`;
    let request_headers = {
        'Content-Type': 'application/json',
        "token": "zaVhURDj3Nyv8yXve0kQBCJe0TBfIKylzNFJCFgi1LYkzVOwYJekt3t1T226fBcc8LEUe2cCNjcwAiurpdH5tspevTsY0FbR9KlbJ50E8Lrd1f652xMxEJqyKfFp4O87"
    }
    let request_options = {
        method: method?.toUpperCase(),
        mode: "cors",
        headers: request_headers
    }
    if(body) request_options.body = JSON.stringify(body)
    return fetch(url, request_options)
}