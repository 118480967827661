import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Plot from 'react-plotly.js';

const GraphModal = ({timestamps, buy_spread, sell_spread, tickers, lookback, title, xLabel, yLabel, histPremDiscRtd, setOpenGraphModal}) => {

  const handleClose = () => {
    setOpenGraphModal(false);
  };

const getCutoffTime = (nMinutes, now = new Date()) => {
  // Valid hours
  const START_HOUR = 9;
  const END_HOUR = 17.5; // 17:30

  // Current time components
  let remainingMinutes = nMinutes;
  let currentDate = new Date(now);

  while (remainingMinutes > 0) {
    // Check if current date is a week day.
    if (![0,6]?.includes(currentDate.getDay())) {
      // Extract current time details
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();

      // Determine if we are inside valid hours.
      const isWithinValidHours =
        currentHour + currentMinute / 60 >= START_HOUR &&
        currentHour + currentMinute / 60 <= END_HOUR;

      if (isWithinValidHours) {
        // Calculate the time back to the start of valid hours
        const startOfValid = new Date(currentDate);
        startOfValid.setHours(START_HOUR, 0, 0, 0);

        const minutesSinceStartOfValid =
            (currentDate - startOfValid) / (1000 * 60); // Milliseconds to minutes

        if (remainingMinutes <= minutesSinceStartOfValid) {
            // If n minutes fit within this valid period
            return new Date(
                currentDate.getTime() - remainingMinutes * 60 * 1000
            );
        }

        // Otherwise, subtract all the minutes in this day
        remainingMinutes -= minutesSinceStartOfValid;
      }
    }

    // Move to the previous day
    currentDate.setDate(currentDate.getDate() - 1);
    currentDate.setHours(END_HOUR, 0, 0, 0); // Set to end of valid hours on day
  }

  return currentDate;
}

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      width: "800px",
      height: "800px",
      backgroundColor: "#303030",
      zIndex: 9999,
      display: "flex",
      flexDirection: "column",
      border: "2px solid black"}}>

      {/* Close Button */}
      <div style={{position: "absolute", top: "5px", right: "5px", cursor: "pointer", color: "white", fontSize: "20px"}} onClick={handleClose}>
        &times;
      </div>

      <div style={{flex: 1, padding: 20, float: 'left', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Tabs style={{marginTop: 30}}>
          <TabList>
            <Tab>Spread</Tab>
            <Tab>Prem/Disc</Tab>
            {tickers?.map(ticker => (
              <Tab>{ticker}</Tab>
            ))}
          </TabList>
          <TabPanel>
            <Plot
              data={
              [{
                x: timestamps,
                y: buy_spread,
                type: 'scatter',
                mode: 'lines',
                name: 'Buy',
                hoverinfo: "text+skip",
                text: buy_spread?.map((_, i) => 
                `${buy_spread?.[i]}%<br>${timestamps?.[i]}`),
                line: {width: 0.5}
              },{
                x: timestamps,
                y: sell_spread,
                type: 'scatter',
                mode: 'lines',
                name: 'Sell',
                hoverinfo: "text+skip",
                text: sell_spread?.map((_, i) => 
                `${sell_spread?.[i]}%<br>${timestamps?.[i]}`),
                line: {width: 0.5}
              }]
              }
                layout={ 
                {
                  title: {
                    text: title,
                    font: {
                      size: 24
                    }
                  },
                  width: 675, 
                  height: 650, 
                  showlegend: true,
                  xaxis: {
                    title: xLabel, 
                    zeroline: false,
                    showline: false,
                    rangebreaks: [
                      // Exclude weekends
                      { bounds: ["sat", "mon"] },
                      // Exclude hours outside of 9:00 to 17:30
                      { bounds: [17.5, 9], pattern: "hour" }
                    ]
                  }, 
                  yaxis: {
                    title: yLabel,
                    zeroline: false,
                    showline: false
                  },
                  legend: {
                    orientation: "h", 
                    y: 1.1,
                  },
                  shapes: [
                    {
                      type: 'line',
                      x0: timestamps.find(timestamp => new Date(timestamp) > getCutoffTime(lookback)),
                      x1: timestamps.find(timestamp => new Date(timestamp) > getCutoffTime(lookback)),
                      y0: 0,
                      y1: 1,
                      xref: 'x',
                      yref: 'paper',
                      line: {
                        color: 'blue',
                        width: 2,
                        dash: 'solid',
                      },
                    },
                  ],
                } 
              }
              config={{
                displayModeBar: false, // Disable the mode bar
              }}
            />
          </TabPanel>
          <TabPanel>
            <Plot
              data={[
                ...tickers?.map(ticker => {
                  return {
                    x: histPremDiscRtd?.[ticker]?.time?.getArray(),
                    y: histPremDiscRtd?.[ticker]?.mid?.getArray()?.map(e => parseFloat((e*100)?.toFixed(2))),
                    type: 'scatter',
                    mode: 'lines',
                    name: ticker,
                    hoverinfo: "text+skip",
                    text: histPremDiscRtd?.[ticker]?.mid?.getArray()?.map((_, i) => 
                    `${(histPremDiscRtd?.[ticker]?.mid?.getArray()?.[i]*100)?.toFixed(2)}%<br>${histPremDiscRtd?.[ticker]?.time?.getArray()?.[i]}`),
                    line: {width: 0.5}
                  } 
                })]
              }
                layout={ 
                {
                  title: {
                    text: "Prem/Disc",
                    font: {
                      size: 24
                    }
                  },
                  width: 675, 
                  height: 650, 
                  showlegend: true,
                  xaxis: {
                    title: 'Timestep', 
                    zeroline: false,
                    showline: false,
                    rangebreaks: [
                      // Exclude weekends
                      { bounds: ["sat", "mon"] },
                      // Exclude hours outside of 9:00 to 17:30
                      { bounds: [17.5, 9], pattern: "hour" }
                    ]
                  }, 
                  yaxis: {
                    title: 'Prem/Disc (%)',
                    zeroline: false,
                    showline: false
                  },
                  legend: {
                    orientation: "h", 
                    y: 1.1,
                  },
                } 
              }
              config={{
                displayModeBar: false, // Disable the mode bar
              }}
            />
          </TabPanel>
          {tickers?.map(ticker => (
            <TabPanel>
              <Plot
                data={
                [{
                  x: histPremDiscRtd?.[ticker]?.time?.getArray(),
                  y: histPremDiscRtd?.[ticker]?.bid?.getArray()?.map(e => parseFloat((e*100)?.toFixed(2))),
                  type: 'scatter',
                  mode: 'lines',
                  name: 'Bid',
                  hoverinfo: "text+skip",
                  text: histPremDiscRtd?.[ticker]?.bid?.getArray()?.map((_, i) => 
                  `${(histPremDiscRtd?.[ticker]?.bid?.getArray()?.[i]*100)?.toFixed(2)}%<br>${histPremDiscRtd?.[ticker]?.time?.getArray()?.[i]}`),
                  line: {width: 0.5}
                },
                {
                  x: histPremDiscRtd?.[ticker]?.time?.getArray(),
                  y: histPremDiscRtd?.[ticker]?.mid?.getArray()?.map(e => parseFloat((e*100)?.toFixed(2))),
                  type: 'scatter',
                  mode: 'lines',
                  name: 'Mid',
                  hoverinfo: "text+skip",
                  text: histPremDiscRtd?.[ticker]?.mid?.getArray()?.map((_, i) => 
                  `${(histPremDiscRtd?.[ticker]?.mid?.getArray()?.[i]*100)?.toFixed(2)}%<br>${histPremDiscRtd?.[ticker]?.time?.getArray()?.[i]}`),
                  line: {width: 0.5}
                },
                {
                  x: histPremDiscRtd?.[ticker]?.time?.getArray(),
                  y: histPremDiscRtd?.[ticker]?.ask?.getArray()?.map(e => parseFloat((e*100)?.toFixed(2))),
                  type: 'scatter',
                  mode: 'lines',
                  name: 'Ask',
                  hoverinfo: "text+skip",
                  text: histPremDiscRtd?.[ticker]?.ask?.getArray()?.map((_, i) => 
                  `${(histPremDiscRtd?.[ticker]?.ask?.getArray()?.[i]*100)?.toFixed(2)}%<br>${histPremDiscRtd?.[ticker]?.time?.getArray()?.[i]}`),
                  line: {width: 0.5}
                }]
                }
                  layout={ 
                  {
                    title: {
                      text: ticker,
                      font: {
                        size: 24
                      }
                    },
                    width: 675, 
                    height: 650, 
                    showlegend: true,
                    xaxis: {
                      title: 'Timestep', 
                      zeroline: false,
                      showline: false,
                      rangebreaks: [
                        // Exclude weekends
                        { bounds: ["sat", "mon"] },
                        // Exclude hours outside of 9:00 to 17:30
                        { bounds: [17.5, 9], pattern: "hour" }
                      ]
                    }, 
                    yaxis: {
                      title: 'Prem/Disc (%)',
                      zeroline: false,
                      showline: false
                    },
                    legend: {
                      orientation: "h", 
                      y: 1.1,
                    },
                  } 
                }
                config={{
                  displayModeBar: false, // Disable the mode bar
                }}
              />
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </div> 
  );
};

export default GraphModal;
