import React from 'react'

const NoMatch = () => {
    return (
        <main>
            <h1 style= {{color: "white"}}>Sorry...</h1>
            <p>
                We couldn't find the page you were looking for.
            </p>
        </main>
    )
}

export default NoMatch;