import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import Context from '../store/context';
import Switch from "react-switch";
import { deepPath, deepUpdate, default_settings, standard_notification, switch_default } from "../utils";
import { merge } from "lodash";
import api from "../api/api";
import { store } from "react-notifications-component";

var menu = {
    name: "Settings",
    root: true,
    children: [
        {
            name: "Preferences",
            children: [
                {
                    name: "Graphs",
                    children: [
                        {
                            name: "Default trailing days",
                            input: {path: ["settings", "preferences", "plot_from_days_ago"], type: "number", min: 1, step: 1}
                        },
                        {
                            name: "Favorite sector",
                            input: {path: ["settings", "preferences", "plot_favorite_sector"], type: "dropdown", choices: [
                                "AMERICA", "EM", "EU", "GLOBAL", "HEALTH", "TECH", "JAPAN", "SPECIALIST", "UK SMALL", "UK LARGE", "UK OTHER"
                            ]}
                        }
                    ]
                }
                // {
                //     name: "Text size",
                //     input: {path: ["settings", "preferences", "text_size"], type: "number"}
                // }
            ]
        },
        {
            name: "Data Throttling",
            children: [
                {
                    name: "Disable Streaming Data",
                    switch_btn:{path: ["settings", "disable_streaming_data", "disable_streaming_data"], default_value: false}
                },
            ]
        },
    ]
}

const SettingsMenu = () => {
    const {user, setUser, globalState, setGlobalState} = useContext(Context)
    const [currentMenu, setCurrentMenu] = useState(menu)
    const [menuStack, setMenuStack] = useState([])
    return (
        <div className='nav-header'>
            <FontAwesomeIcon icon={faCog} color="#111" style={{cursor: "pointer"}} onClick={() => {
                setCurrentMenu(menu)
                setMenuStack([])
                setGlobalState({...globalState, modals: {...globalState?.modals, settings: !globalState?.modals?.settings, notifications: false}})
            }}/>
            {globalState?.modals?.settings && (
                <ul style={styles.subMenu}>
                    {currentMenu?.root ?? <li onClick={() => {
                        if(menuStack.length > 0){
                            setCurrentMenu(menuStack.at(-1))
                            setMenuStack(menuStack.slice(0,-1))
                        }
                        }}><FontAwesomeIcon icon={faAngleLeft}/>&nbsp;{currentMenu?.name ?? currentMenu.name}</li>}
                    {currentMenu.children.map(({name, switch_btn,input, children}) => (
                        <li key={name} className="navlink">
                            <span onClick={() => {
                            if(children){
                                setMenuStack(menuStack.concat(currentMenu))
                                setCurrentMenu({name, switch_btn, children, parent: currentMenu})
                            }
                        }}> {name} </span>&nbsp;
                            {input && 
                            <span style={{float:input?.side ?? "right", display: "inline"}}>
                            {input?.type === "dropdown" ? (
                                <select value={deepPath(user, input?.path) ?? input?.default_value}
                                onChange={e => setUser(deepUpdate(user, input?.path, e.target.value))}>
                                    {input?.choices?.map(choice => <option>{choice}</option>)}
                                </select>
                            ):(<input type={input?.type ?? "text"} 
                            min={input?.min ?? 0} max={input?.max ?? 100} step={input?.step ?? 1} style={styles.slider}
                            value={deepPath(user, input?.path) ?? input?.default_value ?? deepPath(default_settings, input?.path?.slice(1))}
                            onChange={e => setUser(deepUpdate(user, input?.path, e.target.value))}/>)}
                            </span>}
                            {switch_btn && <span style={{float:switch_btn?.side ?? "left", marginRight: 5}}><Switch {...switch_default} checked={deepPath(user, switch_btn?.path) ?? switch_btn?.default_value ?? deepPath(default_settings, switch_btn?.path?.slice(1))} onChange={check => setUser(deepUpdate(user, switch_btn?.path, check))}/></span>}
                            {children && <FontAwesomeIcon icon={faAngleRight}/>}
                        </li>
                    ))}
                    <button style={styles.saveBtn}
                    onClick={() => api("users/settings", "POST", {email: user?.email, settings: JSON.stringify(user?.settings)}).then(x => x.json()).then(({success, error}) => {
                        if(success) store.addNotification({...standard_notification, message: "Settings updated", type: "success"}); else alert(`Settings could not be saved. Error: ${JSON.stringify(error)}`);
                    })}>Save</button>
                </ul>
            )}
        </div>
    )
}

const styles = {
    subMenu: {
      position:"absolute",
      backgroundColor: "white",
      marginTop: 5,
      borderRadius: 6,
      boxShadow: "0 10px 35px rgba(111,111,111,0.85)",
      zIndex: 99999
    },
    checkbox: {
      height: 15,
      minWidth: 15,
      margin: 0,
      display: "inline-block",
    },
    slider: {
        maxWidth: 100, 
        minWidth: 50, 
        margin:0, 
        height: 8
    },
    saveBtn: {
        margin: "0 15px 15px 15px",
        width: "80%"
    }
  }

export default SettingsMenu;