import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Context from '../store/context';
import api from '../api/api';
import Modal from 'react-modal';

const Verify = () => {
    const { user, setUser} = useContext(Context)
    const [modalIsOpen, setModalOpen] = useState(false);
    const [msg, setMsg] = useState();
    const [tempUser, setTempUser] = useState();
    const [valid, setValid] = useState(false);

    const navigate = useNavigate()
    const getVerificationId = qs => {
        if(qs !== "") return qs.slice(1).split("&").filter(x => x.split("=")[0]==="id")[0].split("=")[1]
        return ""
    }
    
    const [verificationId] = useState(getVerificationId(window.location.search));
    const modalLeave = () => {
        setModalOpen(false);
        if(valid){
            setUser({...tempUser, valid: true});
            navigate("../")
        }
    }

    useEffect(() => {
        if(verificationId !== ""){
            api(`users/token/verify/${verificationId}`, "get").then(response => response.json()).then(({success, error, user}) => {
                if(success === true){
                    setTempUser(user)
                    setMsg((<div><p>Your profile has now been verified. You will be logged in with the following email:</p>
                        <p>{user?.email}</p></div>))
                    setValid(true)
                } else {
                    setMsg((<p>An error occured in your profile verification: {error}</p>))
                }
                setModalOpen(true)
            })
        }
    }, [verificationId])
    return (
    <main style={{height: "100%", boxSizing: "inherit"}}>
        <p>Verify</p>
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={modalLeave}
        style={styles.modal}
      >
        <div style={styles.content}>
          {msg}
          <div>
            <button style={{margin: 20}} onClick={modalLeave}>OK</button>
            <button onClick={() => alert(JSON.stringify(user))}>gimme</button>
          </div>
        </div>
        </Modal>
    </main>
    )
}

const styles = {
    login: {
        height: "100%",
        boxSizing: "inherit",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        verticalAlign: "middle",
        margin: "30%",
        marginTop: "20vh",
        padding: 20,
        border: "2px solid white",
        borderRadius: 25,
    },
    logo: {
        padding: 10,
        borderRadius: 25,
    },
    modal: {
        content: {
          backgroundColor: "#111",
          borderRadius: 25,
          minHeight: "10vh",
          maxHeight: "50vh",
          minWidth: "10vh",
          maxWidth: "70vh",
          margin: "auto",
          justifyContent: "center",
          alignSelf: "center",
          alignItems: "center",
          textAlign: "center",
          border: "5px solid #226699"
        }
      },
      content: {
        boxSizing: "inherit",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }
}
export default Verify;