import React, { createContext, useContext, useState, useRef } from 'react';

const StateContext = createContext();

export const AuthStateProvider = ({ children }) => {
  const [apiKey, setApiKey] = useState(null);

  const state = {
    apiKey,
    setApiKey
  };

  return (
    <StateContext.Provider value={state}>
      {children}
    </StateContext.Provider>
  );
};

export const useAuthState = () => useContext(StateContext);