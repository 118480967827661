import React, {useContext, useEffect} from 'react';
import './App.css';
import HeadNav from './components/head-nav';
import Context from './store/context';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import NoMatch from './routes/404';
import Login from './routes/login';
import Verify from './routes/verify';
import ReactNotification from 'react-notifications-component';
import { default_settings } from './utils';
import Graphs from './routes/graphs';
import Reports from './routes/reports';
import Analysis from './routes/analysis';
import Indicators from './routes/indicators';
import Monitor from './routes/monitor';

const App = () => {
  const {user, setUser, globalState, setGlobalState} = useContext(Context);
  
  useEffect(() => {
    // Set a valid anonymous user in development environment
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development' && setUser({...user, email: "test@tricap.dk", valid: true, settings: default_settings})
    document.addEventListener("keydown", e => {
      if(e.key === "Escape") {
        setGlobalState({...globalState, modals: null})
      } else if(e.key === "CapsLock") {
        setGlobalState({...globalState, caps: !e.getModifierState("CapsLock")})
      } else if (["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z", "æ", "ø", "å"].includes(e.key.toLowerCase())) {
        setGlobalState({...globalState, caps: (e.key !== e.key.toLowerCase())})
      }
    })
  }, [])
  return (
      <div className="App">
      {user?.valid ? (
      <Router>
        <HeadNav/>
        <ReactNotification />
        <Routes>
            <Route path="*" element={<NoMatch/>}/>
            <Route path="/" exact element={<Reports/>}/>
            <Route path="/*:params*/index.html" element={<Reports/>}/>
            <Route path="/graphs" exact element={<Graphs/>}/>
            <Route path="/reports" exact element={<Reports/>}/>
            <Route path="/analysis" exact element={<Analysis/>}/>
            <Route path="/indicators" exact element={<Indicators/>}/>
            <Route path="/monitor" exact element={<Monitor/>}/>
        </Routes>
      </Router>
    ):(
      <Router>
        <Routes>
          <Route path="/" exact element={<Login/>} />
          <Route path="*" element={<Navigate to=""/>}/>
          <Route path="/verify" element={<Verify/>} />
        </Routes>
      </Router>
    )
  }
  </div>);
}

const styles = {
  modal: {
    content: {
      backgroundColor: "#111",
      borderRadius: 25,
      minHeight: "10vh",
      maxHeight: "50vh",
      minWidth: "10vh",
      maxWidth: "70vh",
      margin: "auto",
      justifyContent: "center",
      alignSelf: "center",
      alignItems: "center",
      textAlign: "center",
      border: "5px solid #226699"
    }
  }
}

export default App;
