import React, { useContext, useEffect, useState } from 'react';
import Context from '../store/context';
import api from '../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { tricap_token_key } from '../utils';

const days_til_token_expiration = 30

const Login = () => {
    const {user, setUser, globalState} = useContext(Context)
    const [password, setPassword] = useState("");
    const [email, setemail] = useState("");
    
    useEffect(() => {
        var token = window.localStorage.getItem(tricap_token_key)
        if (token && user != null){
            token = JSON.parse(token)
            if(token?.expires > (new Date())) api(`users/verify/${token?.token}`).then(x => x.json()).then(res => res?.success ? setUser({...res?.user, valid: true}):alert(JSON.stringify(res?.error)))
        }
    },[])

    const login = e => {
        document.body.classList.add("wait")
        if(e) e?.target?.classList?.add("wait")
        api("login", "POST", {email, password}).then(response => response.json()).then(({success, error, token, settings}) => {
            if(e) e?.target?.classList?.remove("wait")
            document.body.classList.remove("wait")
            if(success === true){
                setUser({email, token, settings, valid: true});
                window.localStorage.setItem(tricap_token_key, JSON.stringify({token, expires: (new Date()).setDate((new Date()).getDate()+days_til_token_expiration)}))
            } else alert(JSON.stringify(error));
        })
    }

    const signup = e => {
        document.body.classList.add("wait")
        if(e) e.target.classList.add("wait")
        api("users", "POST", {email, password}).then(res => res.json()).then(({success, error}) => {
            if(e) e.target.classList.remove("wait")
            document.body.classList.remove("wait")
            if(success === true) alert("Signup complete. Click the link in the email that you receive."); else alert(error)
        })
    }
    return (
    <main style={{height: "100%", boxSizing: "inherit"}}>
        <div style={styles.login}>
            <img style={styles.logo} alt="TriCap logo" src={require("../assets/logo.jpeg").default} width="100" height="100"/>
            <label>Email</label>
            <input onChange={e => setemail(e.target.value)}/>
            {globalState?.caps && <p style={{backgroundColor: "red", padding: 10, margin: 5, borderRadius: 10, fontSize: 20, fontWeight: "bold"}}>
                <FontAwesomeIcon icon={faLock}/> &nbsp;
                Caps lock is on</p>}
            <label>Password</label>
            <input type="password" onChange={e => setPassword(e.target.value)}
            onKeyDown={k => k.key === "Enter" && login()}/>
            <button onClick={login}>Log in</button>
            <button onClick={signup} style={{marginTop: 15}}>Sign up</button>
        </div>
    </main>
    )
}

const styles = {
    login: {
        height: "100%",
        boxSizing: "inherit",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        verticalAlign: "middle",
        margin: "30%",
        marginTop: "20vh",
        padding: 20,
        border: "2px solid white",
        borderRadius: 25,
    },
    logo: {
        padding: 10,
        borderRadius: 25,
    }
}
export default Login;