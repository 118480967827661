import React, { useState, useEffect } from 'react';
import api from '../api/api';
import { styles } from '../global/styles';
import AddStatArbPositionsModal from './add-stat-arb-positions-modal';
import StatArbPremDiscScenariosModal from './stat-arb-prem-disc-scenarios-modal';

const StatArbPositionsModal = ({spreadId, indicatorsData, statArbPositions, statArbMetadata, allStatArbPositions, allStatArbSinglePositions, setStatArbPositions, setStatArbSinglePositions, tickers, setOpenStatArbPositionsModal, curIndicatorsData, minTransactionValue, premDiscRtd, openStatArbPremDiscScenariosModal, setOpenStatArbPremDiscScenariosModal}) => {
  const [openAddStatArbPositionsModal, setOpenAddStatArbPositionsModal] = useState(false);
  const [closeSinglePositions, setCloseSinglePositions] = useState({});
  const [statArbPremDiscScenariosData, setStatArbPremDiscScenariosData] = useState({});

  const handleClose = () => {
    setOpenStatArbPositionsModal(false);
  };

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      width: "600px",
      height: "600px",
      backgroundColor: "#303030",
      zIndex: 9999,
      display: "flex",
      flexDirection: "column",
      border: "2px solid black"}}>

      {/* Add Stat Arb Positions Modal */}
      {openAddStatArbPositionsModal && (
        <AddStatArbPositionsModal spreadId={spreadId} indicatorsData={indicatorsData} tickers={tickers} statArbMetadata={statArbMetadata} setStatArbPositions={setStatArbPositions} setStatArbSinglePositions={setStatArbSinglePositions} statArbSinglePositions={allStatArbSinglePositions} setOpenAddStatArbPositionsModal={setOpenAddStatArbPositionsModal}/>
      )}

      {/* Stat Arb Prem/Disc Scenarios Modal */}
      {openStatArbPremDiscScenariosModal && (
        <StatArbPremDiscScenariosModal type={"close"} spreadId={spreadId} curIndicatorsData={curIndicatorsData} statArbMetadata={statArbMetadata} minTransactionValue={minTransactionValue} premDiscRtd={premDiscRtd} setOpenStatArbPremDiscScenariosModal={setOpenStatArbPremDiscScenariosModal} statArbPositions={allStatArbPositions} positionId={statArbPremDiscScenariosData?.positionId} singlePositions={statArbPremDiscScenariosData?.singlePositions} closeDirection={statArbPremDiscScenariosData?.closeDirection}/>
      )}

      {/* Close Button */}
      <div style={{position: "absolute", top: "5px", right: "5px", cursor: "pointer", color: "white", fontSize: "20px"}} onClick={handleClose}>
        &times;
      </div>

      <h2 style={{textAlign: "center", marginTop: 50, fontSize: 24, marginBottom: 15}}>Open Positions</h2>
      <hr style={{
          border: '1px solid #000',
          width: '100%',
          marginBottom: 15
      }}/>

      <div style={{flex: 1, padding: 20, float: 'left', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', overflow: 'auto', marginBottom: 60}}>
        <table className="border border-solid border-gray-300/[.45]" style={{ ...styles.table.table, margin: '0 auto', border: '', padding: 0, width: 500 }}>
          <thead style={styles.table.header}>
            <tr style={{fontSize: 14}}>
            <th style={{
              border: 0,
              padding: 10
            }}></th>
            <th style={{
              border: 0,
              padding: 10
            }}>Tickers</th>
            <th style={{
              border: 0,
              padding: 10
            }}>Direction</th>
            <th style={{
              border: 0,
              padding: 10
            }}>Open Spread</th>
            <th style={{
              border: 0,
              padding: 10
            }}>Open Z-Score</th>
            <th style={{
              border: 0,
              padding: 10
            }}>P/L</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(statArbPositions || {})?.map(positionId => {
              let rows = [];
              rows.push(<tr key={positionId} className="hover:bg-gray-700">
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "center",
                    marginRight: 5,
                    padding: 5
                  }}
                ><span className='hover-update' style={{marginTop: 1, marginRight: 15, float: 'left', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 10, height: 15, backgroundColor: '#226699', cursor: 'pointer', borderRadius: 0}} onClick={e => {
                    e.preventDefault()
                    setCloseSinglePositions(prevState => ({...prevState, [positionId]: !closeSinglePositions?.[positionId]}))
                  }}>{!closeSinglePositions?.[positionId] ? '+' : '-'}</span>
                </td>
                {/* Tickers */}
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "center",
                    marginRight: 5,
                    padding: 5
                  }}
                >
                   <span className='hover-red' style={{marginTop: 3, marginRight: 5, float: 'left', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 10, height: 15, backgroundColor: '#A42A04', color: 'black', cursor: 'pointer', borderRadius: 0}} onClick={e => {
                      e.preventDefault()

                      api(`algos/stat-arb-positions/delete`, "POST", {id: [positionId]}).then(res => res.json()).then(data => {
                        if (data?.status === "success") {
                          api(`algos/stat-arb-single-positions/delete`, "POST", {id: [positionId]}).then(res => res.json()).then(data => {
                            if (data?.status === "success") {
                              let updatedStatArbPositions = {...allStatArbPositions};
                              let updatedStatArbSinglePositions = {...allStatArbSinglePositions};
                              delete updatedStatArbPositions[positionId];
                              delete updatedStatArbSinglePositions[positionId];
                              delete statArbPositions[positionId];
                              setStatArbPositions(updatedStatArbPositions);
                              setStatArbSinglePositions(updatedStatArbSinglePositions);
                            } else {
                              console.log("Unable to delete position")
                            }
                          })
                        } else {
                          console.log("Unable to delete position")
                        }
                      });
                    }}>x</span>
                  <span>{tickers?.join("/")}</span>
                </td>
                {/* Direction */}
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "center",
                    backgroundColor:
                      statArbPositions?.[positionId]?.direction?.toLowerCase() === "buy"
                      ? "green"
                      : statArbPositions?.[positionId]?.direction?.toLowerCase() === "sell"
                      ? "red"
                      : "",
                    color: "black",
                    padding: 5
                  }}
                >{
                  statArbPositions?.[positionId]?.direction?.toLowerCase() === "buy" ?
                  "BUY" :
                  statArbPositions?.[positionId]?.direction?.toLowerCase() === "sell" ?
                  "SELL"
                  : ""
                }</td>
                {/* Open Spread */}
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "right",
                    marginRight: 5,
                    padding: 5
                  }}
                >{`${parseFloat(statArbPositions?.[positionId]?.open_spread*100)?.toFixed(2)}%`}</td>
                {/* Open Z-Score */}
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "right",
                    marginRight: 5,
                    padding: 5
                  }}
                >{parseFloat(statArbPositions?.[positionId]?.z_score)?.toFixed(2)}</td>
                {/* P/L */}
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "right",
                    marginRight: 5,
                    padding: 5,
                    color: 
                      statArbPositions?.[positionId]?.direction?.toLowerCase() === "buy" ?
                        parseFloat(indicatorsData?.[spreadId]?.Indicator?.sell_spread[indicatorsData?.[spreadId]?.Indicator?.sell_spread?.length - 1])-parseFloat(statArbPositions?.[positionId]?.open_spread) > 0
                        ? "green" : "red"
                      :
                      parseFloat(statArbPositions?.[positionId]?.open_spread)-parseFloat(indicatorsData?.[spreadId]?.Indicator?.buy_spread[indicatorsData?.[spreadId]?.Indicator?.buy_spread?.length - 1]) > 0
                        ? "green" : "red"
                  }}
                >{
                  statArbPositions?.[positionId]?.direction?.toLowerCase() === "buy" ?
                  `${((parseFloat(indicatorsData?.[spreadId]?.Indicator?.sell_spread[indicatorsData?.[spreadId]?.Indicator?.sell_spread?.length - 1])-parseFloat(statArbPositions?.[positionId]?.open_spread))*100)?.toFixed(2)}%` :
                  `${((parseFloat(statArbPositions?.[positionId]?.open_spread)-parseFloat(indicatorsData?.[spreadId]?.Indicator?.buy_spread[indicatorsData?.[spreadId]?.Indicator?.buy_spread?.length - 1]))*100)?.toFixed(2)}%`
                  }</td>
              </tr>)

              if (closeSinglePositions?.[positionId]) {
                rows.push(
                  <tr style={{ height: 25, fontSize: 10 }}>
                    <td colSpan={9} style={{ textAlign: 'center' }}>
                      <table className="border border-solid border-gray-300/[.45]" style={{ ...styles.table.table, margin: '0 auto', border: '', padding: 0, width: 300 }}>
                        <thead style={styles.table.header}>
                          <tr style={{fontSize: 14}}>
                          <th style={{
                            border: 0,
                            padding: 10
                          }}>Ticker</th>
                          <th style={{
                            border: 0,
                            padding: 10
                          }}>Direction</th>
                          <th style={{
                            border: 0,
                            padding: 10
                          }}>Prem/Disc</th>
                          <th style={{
                            border: 0,
                            padding: 10
                          }}>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                        {allStatArbSinglePositions?.[positionId].map((singlePosition, i) => (
                          <tr className="hover:bg-gray-700" key={i}>
                            {/* Ticker */}
                            <td
                              className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                              style={{
                                ...styles.table.algos_table_cell,
                                textAlign: "center",
                                marginRight: 5,
                                padding: 5
                              }}
                            >
                              {singlePosition?.ticker}
                            </td>
                            {/* Direction */}
                            <td
                              className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                              style={{
                                ...styles.table.algos_table_cell,
                                textAlign: "center",
                                backgroundColor:
                                  singlePosition?.amount > 0
                                    ? "green"
                                    : "red",
                                color: "black",
                                padding: 5
                              }}
                            >{
                              singlePosition?.amount > 0
                                ? "BUY"
                                : "SELL"
                            }</td>
                            {/* Prem/Disc */}
                            <td
                              className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                              style={{
                                ...styles.table.algos_table_cell,
                                textAlign: "right",
                                marginRight: 5,
                                padding: 5
                              }}
                            >
                              {`${parseFloat(singlePosition?.prem_disc*100)?.toFixed(2)}%`}
                            </td>
                            {/* Amount */}
                            <td
                              className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                              style={{
                                ...styles.table.algos_table_cell,
                                textAlign: "right",
                                marginRight: 5,
                                padding: 5
                              }}
                            >
                              {singlePosition?.amount}
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                      <button className='hover-update' style={{cursor: "pointer", color: "white", marginTop: 10, marginBottom: 10}} onClick={() => {
                        setStatArbPremDiscScenariosData({
                          positionId: positionId,
                          singlePositions: allStatArbSinglePositions?.[positionId],
                          closeDirection: statArbPositions?.[positionId]?.direction === "buy" ? "sell" : "buy"
                        })
                        setOpenStatArbPremDiscScenariosModal(true);
                      }}>
                        Prem/Disc Calculation
                      </button>
                    </td>
                  </tr>
                )
              }
              return rows;
            })}
          </tbody>
        </table>
      </div>

      {/* Close Button */}
      <button className='hover-update' style={{position: "absolute", bottom: "15px", right: "15px", cursor: "pointer", color: "white", fontSize: "16px"}} onClick={() => {
        setOpenAddStatArbPositionsModal(true);
      }}>
        Add Position
      </button>
    </div> 
  );
};

export default StatArbPositionsModal;
