import React, { useState, useEffect } from 'react';
import api from '../api/api';
import { styles } from '../global/styles';
import { mean, std } from '../utils'

const AddStatArbPositionsModal = ({spreadId, indicatorsData, tickers, statArbMetadata, setStatArbPositions, setStatArbSinglePositions, statArbSinglePositions, setOpenAddStatArbPositionsModal}) => {
  const [position, setPosition] = useState({});

  const handleClose = () => {
    setOpenAddStatArbPositionsModal(false);
  };

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      width: "600px",
      height: "600px",
      backgroundColor: "#303030",
      zIndex: 9999,
      display: "flex",
      flexDirection: "column",
      border: "2px solid black"}}>

      {/* Close Button */}
      <div style={{position: "absolute", top: "5px", right: "5px", cursor: "pointer", color: "white", fontSize: "20px"}} onClick={handleClose}>
        &times;
      </div>

      <h2 style={{textAlign: "center", marginTop: 50, fontSize: 24, marginBottom: 15}}>Add Position</h2>
      <hr style={{
          border: '1px solid #000',
          width: '100%',
          marginBottom: 15
      }}/>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 15 }}>
        {/* Direction Selector */}
        <div style={{ display: 'flex', justifyContent: 'space-between', width: 400 }}>
          <p style={{ fontSize: 16 }}>
            Direction:
          </p>
          <select
            defaultValue={""}
            onChange={(e) => {
              e?.persist();
              setPosition(prevState => ({ ...prevState, direction: e?.target?.value }))
            }}
            style={{ width: 100, color: 'black', fontSize: 11 }}
            value={position?.direction}>
            <option disabled value={""}>Select Direction</option>
            <option value={"buy"}>Buy</option>
            <option value={"sell"}>Sell</option>
          </select>
        </div>
        <table style={{
          fontSize: 10,
          whiteSpace: 'nowrap',
          width: 400,
        }}>
          <thead style={styles.table.header}>
            <tr style={{fontSize: 18}}>
              <th style={{
                border: 0,
                padding: 10
              }}>Ticker</th>
              <th style={{
                border: 0,
                padding: 10
              }}>Prem/Disc</th>
              <th style={{
                border: 0,
                padding: 10
              }}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {tickers?.map((ticker, i) => (
              <tr key={i} className="hover:bg-gray-700" style={{fontSize: 12}}>
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{ ...styles.table.algos_table_cell, textAlign: "center", padding: 5 }}
                >{ticker}</td>
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{ ...styles.table.algos_table_cell, textAlign: "center", padding: 5 }}
                ><input style={{maxWidth: 175, ...styles.input, width: 175, fontSize: 11, height: 15}}
                  value={position?.[ticker]?.prem_disc}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || value === '-' || (parseFloat(value) >= -1000000000000000000 && parseFloat(value) <= 1000000000000000000)) {
                      setPosition(prevState => ({...prevState, [ticker]: {...prevState?.[ticker], prem_disc: value}}));
                    }
                  }}
                /></td>
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{ ...styles.table.algos_table_cell, textAlign: "center", padding: 5 }}
                ><input style={{maxWidth: 175, ...styles.input, width: 175, fontSize: 11, height: 15}}
                  value={position?.[ticker]?.amount}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || value === '-' || (parseInt(value) >= -1000000000000000000 && parseInt(value) <= 1000000000000000000)) {
                      setPosition(prevState => ({...prevState, [ticker]: {...prevState?.[ticker], amount: value}}));
                    }
                  }}
                /></td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          className="hover-update"
          style={{ fontSize: 11, marginTop: 10, textAlign: 'center', width: 150, float: 'right'}} onClick={() => {
            // Generate position id.
            const id = crypto.randomUUID();

            // Calculate the spread where the position is opened at from basket weights and individual prem/disc.
            let openSpread = 0;
            for (let i=0; i<tickers?.length; i++) {
              openSpread += statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(tickers?.[i])]*position?.[tickers?.[i]]?.prem_disc;
            }

            // Calculate the z-score of the open spread.
            let openZScore;
            if (position?.direction === "buy") {
              const spreadMean = indicatorsData?.[spreadId]?.Indicator?.buy_mean;
              const spreadStd = indicatorsData?.[spreadId]?.Indicator?.buy_std;
              openZScore = (openSpread - spreadMean) / spreadStd;
            } else if (position?.direction === "sell") {
              const spreadMean = indicatorsData?.[spreadId]?.Indicator?.sell_mean;
              const spreadStd = indicatorsData?.[spreadId]?.Indicator?.sell_std;
              openZScore = (openSpread - spreadMean) / spreadStd;
            }

            const body = {
              'id': [id],
              'spread_id': [spreadId],
              'open_spread': [String(openSpread)],
              'direction': [position?.direction],
              'z_score': [String(openZScore)]
            }
            api("algos/stat-arb-positions/insert", "POST", body).then(res => res.json()).then(data => {
              if (data?.status === 'success') {
                // Create single positions.
                let body = {
                  'id': [id],
                  'tickers': [],
                  'amount': [],
                  'prem_disc': []
                };
                for (let i=0; i<tickers?.length; i++) {
                  body["tickers"].push(tickers?.[i]);
                  body["amount"].push(String(position?.[tickers?.[i]]?.amount));
                  body["prem_disc"].push(String(position?.[tickers?.[i]]?.prem_disc));
                }
                api("algos/stat-arb-single-positions/insert", "POST", body).then(res => res.json()).then(data => {
                  if (data?.status === 'success') {
                    // Update state variables.
                    setStatArbPositions(prevState => ({...prevState, [id]: {
                      spread_id: parseInt(spreadId), 
                      open_spread: openSpread, 
                      direction: position?.direction,
                      z_score: openZScore
                    }
                    }));

                    let updatedStatArbSinglePositions = {...statArbSinglePositions};
                    updatedStatArbSinglePositions[id] = [];
                    for (let i=0; i<tickers?.length; i++) {
                      updatedStatArbSinglePositions[id].push({
                        'ticker': tickers?.[i],
                        'amount': position?.[tickers?.[i]]?.amount,
                        'prem_disc': position?.[tickers?.[i]]?.prem_disc
                      })
                    }
                    setStatArbSinglePositions(updatedStatArbSinglePositions);

                    // Close modal.
                    setOpenAddStatArbPositionsModal(false);
                  } else {
                    console.log(data?.error)
                  }
                })
                .catch(error => console.error(error));

              } else {
                console.log(data?.error)
              }
            })
            .catch(error => console.error(error));
          }}>
          Add
        </button>
      </div>
    </div> 
  );
};

export default AddStatArbPositionsModal;
