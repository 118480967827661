require("dotenv").config()

export default (endpoint, method, body) => {
    let url = `https://trading.tricap.dk:8000/api/${endpoint}`;
    let request_headers = {
        'Content-Type': 'application/json',
        "token": "U7Oaj70mPYZBN532WDzSU3uES3f6cM9YBvxLGVgQcwIgIlGKiGMPzdpnY44kr6DVSULdLLi9ktn8eLP2ieubMuFAnnlYL9tC19gdCypEHb76qifi9lSvZAr8L6zvXoaJ"
    }
    let request_options = {
        method: method?.toUpperCase(),
        mode: "cors",
        headers: request_headers
    }
    if(body) request_options.body = JSON.stringify(body)
    return fetch(url, request_options)
}