import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import '../App.css';
import Context from '../store/context';
import api from '../api/api';
import betaRegressionApi from '../api/beta-regression-api';
import portfolioAnalysisApi from '../api/portfolio-analysis-api';
import portfolioOptimizationApi from '../api/portfolio-optimization-api';
import { styles } from '../global/styles';
import Plot from 'react-plotly.js';
import { Select, Input } from 'antd';
import { sum } from 'lodash';
import LoadingSpinner from "../components/loading-spinner";
import Password from 'antd/es/input/Password';
import Sidebar from '../components/sidebar';

const { Option } = Select;

const getRowColor = () => {
    let lastValue = null;
    let lastColor = null;
    return (value) => {
        if (value === lastValue) {
            return lastColor; // same color as last row
        } else {
            const newColor = lastColor === "#e2f0d9" ? null : "#e2f0d9"; // alternate color
            lastValue = value;
            lastColor = newColor;
            return newColor;
        }
    };
};

const Analysis = () => {
    const { user, setUser, globalState} = useContext(Context);
    const [resultsSettings, setResultsSettings] = useState({});
    const [results, setResults] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [hedgeDataLoaded, setHedgeDataLoaded] = useState(false);
    const [visualizationTitle, setVisualizationTitle] = useState("SMT");
    const [funds, setFunds] = useState([]);
    const [fundSectors, setFundSectors] = useState({});
    const [visualizationType, setVisualizationType] = useState();
    const [page, setPage] = useState("single_regression");
    const [fundOrdering, setFundOrdering] = useState({});
    const [curHedgingInstrument, setCurHedgingInstrument] = useState([]);
    const [hedgingInstruments, setHedgingInstruments] = useState([]);
    const [hedgingInstrumentsDataType, setHedgingInstrumentsDataType] = useState("nav");
    const [betaRegression, setBetaRegression] = useState({});
    const [portfolioAnalysisData, setPortfolioAnalysisData] = useState({});
    const [portfolioOptimizationData, setPortfolioOptimizationData] = useState({});
    const [betaRegressionSettings, setBetaRegressionSettings] = useState({
        fund: "SMT", start_date: new Date(new Date().setDate(new Date().getDate()-90)).toISOString().slice(0, 10),
        end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10),
    });
    const [curPortfolioAnalysisFunds, setCurPortfolioAnalysisFunds] = useState([])
    const [portfolioAnalysisSettings, setPortfolioAnalysisSettings] = useState({
        funds: ["PCT", "ATT"], start_date: new Date(new Date().setDate(new Date().getDate()-90)).toISOString().slice(0, 10),
        end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10),
        nav_type: "official"
    });
    const [portfolioOptimizationSettings, setPortfolioOptimizationSettings] = useState({
        start_date: new Date(new Date().setDate(new Date().getDate()-90)).toISOString().slice(0, 10),
        end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10),
        objective: "min_volatility",
        lower_bound: -1.0,
        upper_bound: 1.0,
        weights_sum: 1.0,
        min_weight: 0.1,
        min_assets: 1,
        eta: 0.0002,
        funds: [],
        sector: ""
    });

    const getRowColorFunc = getRowColor();
      
    const dateDiff = (date1, date2) => {
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        return diffDays;
    };

    useEffect(() => {
        async function getResults() {
            if (resultsSettings?.fund) {
                await api(`fund-data/get-beta-regression-results?fund=${resultsSettings?.fund}`).then(res => res.json()).then(data => {setResults(data)});
            } else if (resultsSettings?.date) {
                await api(`fund-data/get-beta-regression-results?date=${resultsSettings?.date}`).then(res => res.json()).then(data => {setResults(data)});
            } else {
                await api("fund-data/get-beta-regression-results").then(res => res.json()).then(data => {setResults(data)});
            }
        }
        if (page == 'results') {
            getResults()
        }
    }, [page, resultsSettings])

    useEffect(() => {
        async function getCurHedge() {
            await api(`fund-data/current-hedge?ticker=${betaRegressionSettings?.fund}`).then(res => res.json()).then(data => {
                setBetaRegressionSettings({...betaRegressionSettings, 
                    hedging_instruments: data?.data?.hedging_instruments, 
                    hedging_ratios: data?.data?.hedging_ratios, 
                    beta_actual: data?.data?.beta_exposure,
                    lower_bounds: data?.data?.hedging_instruments?.map((instrument, i) => 0.0),
                    upper_bounds: data?.data?.hedging_instruments?.map((instrument, i) => 1.0),
                });
                setHedgeDataLoaded(true);
            });    
        }
        getCurHedge()
    }, [])

    useEffect(() => {
        async function getFunds() {
            await api("fund-data/all-funds").then(res => res.json()).then(data => {
                setFunds(data?.data);
                setPortfolioOptimizationSettings(prevState => ({...prevState, funds: data?.data}))
            });
        }
        getFunds()
    }, [])

    useEffect(() => {
        async function getFundSectors() {
            await api("fund-data/sectors").then(res => res.json()).then(data => {
                setFundSectors(data?.data);
            });
        }
        getFundSectors()
    }, [])

    useEffect(() => {
        async function getHedgingInstruments() {
            await api("hedging-instruments").then(res => res.json()).then(data => {
                setHedgingInstruments(data?.data);
            });    
        }
        getHedgingInstruments()
    }, [])

    useEffect(() => {
        if (page !== "portfolio_analysis") {
            setPortfolioAnalysisSettings({
                funds: ["PCT", "ATT"], start_date: new Date(new Date().setDate(new Date().getDate()-90)).toISOString().slice(0, 10),
                end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10),
                nav_type: "official"
            })
        }
    }, [page])

    // Get fund ordering
  useEffect(() => {
    async function getFundOrdering() {
        await api("fund-data/fund-ordering").then(res => res.json()).then(data => {
            setFundOrdering(data);
        });    
    }
    getFundOrdering();
  }, []);

    useEffect(() => {
        setIsLoading(true)
        async function getBetaRegression() {
            setBetaRegression({});
            await betaRegressionApi("regression", "POST", {
                fund: betaRegressionSettings?.fund, 
                hedging_instruments: betaRegressionSettings?.hedging_instruments?.map(str => str.toLowerCase()),
                hedging_ratios: betaRegressionSettings?.hedging_ratios,
                start_date: betaRegressionSettings?.start_date,
                end_date: betaRegressionSettings?.end_date,
                type: hedgingInstrumentsDataType
            })
            .then(response => response.json())
            .then(data => {
                setBetaRegression(data);
                setIsLoading(false)
            });
        }
        if (hedgeDataLoaded) {
            getBetaRegression();
        }
      }, [hedgeDataLoaded]);


    useEffect(() => {
        async function getPortfolioAnalysis() {
            await portfolioAnalysisApi("analysis", "POST", {
                funds: portfolioAnalysisSettings?.funds, 
                start_date: portfolioAnalysisSettings?.start_date,
                end_date: portfolioAnalysisSettings?.end_date,
                nav_type: portfolioAnalysisSettings?.nav_type
            })
            .then(response => response.json())
            .then(data => {
                setPortfolioAnalysisData(data);
                setIsLoading(false)
            });
        }
        if (page === "portfolio_analysis") {
            setIsLoading(true)
            getPortfolioAnalysis();
            setCurPortfolioAnalysisFunds(portfolioAnalysisSettings?.funds)
        }
    }, [page]);

    useEffect(() => {
        async function getPortfolioOptimization() {
            await portfolioOptimizationApi("optimize", "POST", {
                start_date: portfolioOptimizationSettings?.start_date,
                end_date: portfolioOptimizationSettings?.end_date,
                objective: portfolioOptimizationSettings?.objective,
                lower_bound: portfolioOptimizationSettings?.lower_bound,
                upper_bound: portfolioOptimizationSettings?.upper_bound,
                weights_sum: portfolioOptimizationSettings?.weights_sum,
                min_weight: portfolioOptimizationSettings?.min_weight,
                funds: portfolioOptimizationSettings?.funds,
                eta: portfolioOptimizationSettings?.eta,
                min_assets: portfolioOptimizationSettings?.min_assets
            })
            .then(response => response.json())
            .then(data => {
                setPortfolioOptimizationData(data);

                // Alert user if the earliest date used in optimization is greater than the user specified date.
                if (new Date(portfolioOptimizationSettings?.start_date) < new Date(data?.res?.earliest_date)) {
                    alert(`Earliest date used in optimization: ${data?.res?.earliest_date} is greater than user specified date: ${portfolioOptimizationSettings?.start_date}.\n\nThis is due to data not being available historically for one or more tickers included in optimization.\n\nPlease remove these if it is wanted to run analysis for longer period.`)
                }
                setIsLoading(false);
            });
        }
        if (page === "portfolio_optimization") {
            setIsLoading(true);
            getPortfolioOptimization();
        }
    }, [page]);

    // Dynamically creates input boxes.
    const inputBoxes = useMemo(() => {
    return betaRegressionSettings?.hedging_instruments?.map((instrument, i) => {
        return (
        <div style={{paddingBottom: 20}} key={i}>
            <div style={{ padding: 5 }}>
            <label>{instrument}:</label>
            <Input style={{maxWidth:250}}
                type="number"
                step="0.01"
                value={betaRegressionSettings?.hedging_ratios[i] || 0}
                onChange={(e) => {
                const inputVal = e.target.value.trim();
                    const hedgingRatiosCopy = [
                    ...betaRegressionSettings.hedging_ratios,
                    ];
                    hedgingRatiosCopy[i] = parseFloat(inputVal) || 0;
                    setBetaRegressionSettings({
                    ...betaRegressionSettings,
                    hedging_ratios: hedgingRatiosCopy,
                    });
                }}
            />
            <button className='hover-update'
                style={{ marginLeft: 5, cursor: 'pointer' }}
                onClick={() => {
                const hedgingInstrumentsCopy = [...betaRegressionSettings.hedging_instruments];
                const hedgingRatiosCopy = [...betaRegressionSettings.hedging_ratios];
                const lowerBoundsCopy = [...betaRegressionSettings.lower_bounds];
                const upperBoundsCopy = [...betaRegressionSettings.upper_bounds];
                hedgingInstrumentsCopy.splice(i, 1);
                hedgingRatiosCopy.splice(i, 1);
                lowerBoundsCopy.splice(i, 1);
                upperBoundsCopy.splice(i, 1);
                setBetaRegressionSettings({
                    ...betaRegressionSettings,
                    hedging_instruments: hedgingInstrumentsCopy,
                    hedging_ratios: hedgingRatiosCopy,
                    lower_bounds: lowerBoundsCopy,
                    upper_bounds: upperBoundsCopy,
                });
                }}
            >
                x
            </button>
            </div>
            <div style={{ padding: 5 }}>
            <label>Lower Bound:</label><br/>
            <Input style={{maxWidth:100}}
                type="number"
                step="0.01"
                value={betaRegressionSettings?.lower_bounds?.[i] || 0.0}
                onChange={(e) => {
                    const inputVal = e.target.value.trim();
                    const lowerBoundsCopy = [
                    ...betaRegressionSettings?.lower_bounds,
                    ];
                    lowerBoundsCopy[i] = parseFloat(inputVal) || 0.0;
                    setBetaRegressionSettings({
                    ...betaRegressionSettings,
                    lower_bounds: lowerBoundsCopy,
                    });
                }}
            /><br/>
            <label>Upper Bound:</label><br/>
            <Input style={{maxWidth:100}}
                type="number"
                step="0.01"
                value={betaRegressionSettings?.upper_bounds?.[i] || 0.0}
                onChange={(e) => {
                    const inputVal = e.target.value.trim();
                    const upperBoundsCopy = [
                    ...betaRegressionSettings?.upper_bounds,
                    ];
                    upperBoundsCopy[i] = parseFloat(inputVal) || 0.0;
                    setBetaRegressionSettings({
                    ...betaRegressionSettings,
                    upper_bounds: upperBoundsCopy,
                    });
                }}
            /><br/>
            </div>
        </div>
        );
    });
    }, [betaRegressionSettings]);
      
    if (page === 'results') {
        return (
            <main style={{height: "100%", boxSizing: "inherit"}}>
                <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                    <table border={1} style={{...classes.table, fontSize: "0.8rem"}}>
                        {results?.headers?.map((row, i) => (
                            <thead key={i} style={classes.header}>
                                <tr key={row?.[0]?.name}>
                                {row.map((header, h) => {
                                    var thClass = header?.class ?? ""
                                    if(i === 0) thClass += "sep-right"
                                    return (
                                    <th key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                                )})}
                                </tr>
                            </thead>
                        ))}
                        <tbody>
                            {results?.rows?.map((row, i) => (
                                 <tr className={row?.class} style={{textAlign: "center", backgroundColor: getRowColorFunc(row.values[row.values.length - 1].v)}} key={i}>
                                    {row?.values?.map((val, i) => (<td key={val?.v + i} className={val?.class}>{val?.v}</td>))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Sidebar>
                    <div style={{justifyContent: "space-around", minWidth: 300, margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:50}}>
                        <h3 style={{paddingBottom:5}}>Pages:</h3>
                        <Select
                            style={{ width: 200, paddingBottom:15}}
                            placeholder="Select a page"
                            optionFilterProp="page"
                            defaultValue="single_regression"
                            value={page}
                            onChange={(value) => {
                                setPage(value)
                            }}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                                <Option value="single_regression">Single Regression</Option>
                                <Option value="results">Regression Results</Option>
                                <Option value="portfolio_analysis">Portfolio Correlation Analysis</Option>
                                <Option value="portfolio_optimization">Portfolio Optimization</Option>
                        </Select>
                        <h3 style={{paddingBottom:5}}>Funds:</h3>
                        <Select
                            showSearch
                            style={{ width: 200, paddingBottom:15}}
                            placeholder="Select a fund"
                            optionFilterProp="fund"
                            value={resultsSettings?.fund}
                            onChange={value => setResultsSettings({...resultsSettings, fund: value, date: "", r_squared_filter: "0.6"})}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {funds.map((fundOption) => (
                                <Option value={fundOption}>{fundOption}</Option>
                            ))}
                        </Select>
                        <h3 style={{paddingBottom:5}}>Date:</h3>
                        <input value={resultsSettings?.date} style={{...styles.input, padding: 10, fontSize: 12, maxWidth: 200}} type="date" onChange={e => setResultsSettings({...resultsSettings, date: e?.target?.value, fund: null, r_squared_filter: "0.6"})}/>
                        <p style={{paddingBottom:5, paddingTop:15}}>R-Squared Filter (%):</p>
                        <Input defaultValue="0.6" type="text" value={resultsSettings?.r_squared_filter} onChange={e => {
                            if (/^\d*\.?\d*$/.test(e?.target?.value)) {
                                setResultsSettings({...resultsSettings, r_squared_filter: e?.target?.value, fund: null, date: ""});
                            } else {
                                alert('Please enter a valid float number');
                            }
                        }} /><br/>
                        <div>
                            <button className='hover-update' onClick={() => {
                                async function getBetaRegression() {
                                    await api(`fund-data/get-beta-regression-results?r_squared_filter=${resultsSettings?.r_squared_filter}`).then(res => res.json()).then(data => {setResults(data)});
                                }
                                getBetaRegression()
                            }}>Update R-Squared Filter</button>
                        </div>
                        <div style={{paddingTop:40}}>
                        <button className='hover-update' onClick={() => {
                            async function getBetaRegression() {
                                setResultsSettings({...resultsSettings, fund: null, date: "", r_squared_filter: "0.6"})
                                await api(`fund-data/get-beta-regression-results`).then(res => res.json()).then(data => {setResults(data)});
                            }
                            getBetaRegression()
                        }}>Reset Settings</button>
                        </div>
                    </div>
                </Sidebar>
            </main>
        )
    } else if (page === 'single_regression') {
        return (
            <main style={{height: "100%", boxSizing: "inherit"}}>
                {isLoading && (
                    <div style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <LoadingSpinner style={{ margin: 0, padding: 0 }}/>
                        <button style={{padding: 15, marginTop: 0, width: "5%"}} onClick={() => {
                        setIsLoading(false);
                        }}>Cancel</button>
                    </div>)}
                <div style={{justifyContent: "left", float: "left", display: "flex", paddingTop: 10, flex: 10}}>
                    <div style={{borderRadius: 25, padding: 10, backgroundColor: "#fff"}}>
                    {visualizationType === 'return' ? (
                        <Plot
                            data={[
                                {
                                    x: betaRegression?.dates,
                                    y: betaRegression?.nav_pct_return,
                                    type: 'scatter',
                                    mode: 'lines',
                                    name: 'NAV Total Return',
                                    hoverinfo: "text+skip",
                                    line: {width: 0.5}
                                },
                                {
                                    x: betaRegression?.dates, 
                                    y: betaRegression?.hedge_pct_return,
                                    type: 'scatter',
                                    mode: 'lines',
                                    name: 'Hedge Total Return',
                                    hoverinfo: "text+skip",
                                    line: {width: 0.5}
                                }
                            ]}
                            layout={{
                                width: 1200,
                                height: 800,
                                title: `${visualizationTitle}`, 
                                xaxis: {
                                    title: "Date",
                                }, 
                                yaxis: {
                                    title: "Percentage Return"
                                },
                                legend: {
                                    orientation: "h", 
                                    y: 1.1,
                                },
                            }}
                        />
                    ) : visualizationType === 'beta_adj_return' ? (
                        <Plot
                            data={[
                                {
                                    x: betaRegression?.dates,
                                    y: betaRegression?.nav_pct_return,
                                    type: 'scatter',
                                    mode: 'lines',
                                    name: 'NAV Total Return',
                                    hoverinfo: "text+skip",
                                    line: {width: 0.5}
                                },
                                {
                                    x: betaRegression?.dates, 
                                    y: betaRegression?.hedge_pct_return?.map(num => num * betaRegression?.beta),
                                    type: 'scatter',
                                    mode: 'lines',
                                    name: 'Hedge Total Return (Beta Adjusted)',
                                    hoverinfo: "text+skip",
                                    line: {width: 0.5}
                                }
                            ]}
                            layout={{
                                width: 1200,
                                height: 800,
                                title: `${visualizationTitle}`, 
                                xaxis: {
                                    title: "Date",
                                }, 
                                yaxis: {
                                    title: "Percentage Return"
                                },
                                legend: {
                                    orientation: "h", 
                                    y: 1.1,
                                },
                            }}
                        />
                    ) : (
                        <Plot
                            data={[{
                                    x: betaRegression?.hedge_pct_return,
                                    y: betaRegression?.nav_pct_return, 
                                    type: 'scatter', 
                                    mode: 'markers',
                                    name: 'Beta',
                                    hoverinfo: "text+skip",
                                    line: {width: 0.5}
                                },
                                {
                                    x: betaRegression?.hedge_pct_return, 
                                    y: betaRegression?.pred, 
                                    type: 'scatter', 
                                    mode: 'lines', 
                                    name: 'Regression Line', 
                                    hoverinfo: "text+skip",
                                    line: {width: 2}
                                }
                            ]}
                            layout={{
                                width: 1200,
                                height: 800, 
                                title: `${visualizationTitle}`,
                                xaxis: {
                                    title: "Hedge Percentage Return",
                                }, 
                                yaxis: {
                                    title: "NAV Percentage Return"
                                },
                                legend: {
                                    orientation: "h", 
                                    y: 1.1,
                                },
                            }}
                        />
                    )}
                </div>
                </div>
                <Sidebar page={"regression"}>
                <div style={{justifyContent: "space-around", minWidth:375, maxWidth:375, margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:50, paddingBottom:10}}>
                    <h3 style={{paddingBottom:5}}>Pages:</h3>
                    <Select
                        style={{ width: 200, paddingBottom:15}}
                        placeholder="Select a page"
                        optionFilterProp="page"
                        defaultValue="single_regression"
                        value={page}
                        onChange={(value) => {
                            setPage(value)
                        }}
                        filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                            <Option value="single_regression">Single Regression</Option>
                            <Option value="results">Regression Results</Option>
                            <Option value="portfolio_analysis">Portfolio Correlation Analysis</Option>
                            <Option value="portfolio_optimization">Portfolio Optimization</Option>
                    </Select>
                    <h3 style={{paddingBottom:5}}>Visualization:</h3>
                    <Select
                        style={{ width: 200, paddingBottom:15}}
                        placeholder="Select a visualization"
                        optionFilterProp="visualization"
                        defaultValue="regression"
                        value={visualizationType}
                        onChange={(value) => {
                            setVisualizationType(value)
                        }}
                        filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                            <Option value="regression">Regression</Option>
                            <Option value="return">% Return</Option>
                            <Option value="beta_adj_return">% Return (Beta Adjusted)</Option>
                    </Select>
                    <h3 style={{paddingBottom:5}}>Funds:</h3>
                        <Select
                            showSearch
                            style={{ width: 200, paddingBottom:15}}
                            placeholder="Select a fund"
                            optionFilterProp="fund"
                            value={betaRegressionSettings?.fund}
                            onChange={value => setBetaRegressionSettings({...betaRegressionSettings, fund: value})}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {funds.map((fundOption) => (
                                <Option key={fundOption} value={fundOption}>{fundOption}</Option>
                            ))}
                        </Select>
                        <h3 style={{paddingBottom:5, paddingTop: 30}}>Hedging Instruments:</h3>
                        <Select
                            showSearch
                            style={{ width: 200, paddingBottom:15}}
                            placeholder="Select a hedging instrument"
                            optionFilterProp="hedging_instruments"
                            value={curHedgingInstrument}
                            onChange={value => setCurHedgingInstrument(value)}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {hedgingInstruments.map((hedgingInstrumentOption) => (
                                <Option value={hedgingInstrumentOption}>{hedgingInstrumentOption}</Option>
                            ))}
                        </Select>
                        <div style={{padding: 2}}>
                            <button className='hover-update' style={{minWidth:75}} onClick={() => {
                                if (betaRegressionSettings?.hedging_instruments.includes(curHedgingInstrument)) {
                                    alert('Hedging instrument already added.');
                                } else {
                                    setBetaRegressionSettings({...betaRegressionSettings, 
                                        hedging_instruments: [...betaRegressionSettings.hedging_instruments, curHedgingInstrument],
                                        lower_bounds: [...betaRegressionSettings?.lower_bounds, 0.0],
                                        upper_bounds: [...betaRegressionSettings?.upper_bounds, 1.0],
                                    })
                                }
                            }}>Add</button>
                        </div>
                        <h3 style={{paddingBottom:5, paddingTop: 30}}>Hedging Instruments Datatype:</h3>
                        <Select
                            style={{ width: 200, paddingBottom:15}}
                            placeholder="Select datatype"
                            optionFilterProp="hedging_instruments_datatype"
                            value={hedgingInstrumentsDataType}
                            onChange={value => setHedgingInstrumentsDataType(value)}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            <Option value={"nav"}>NAV (Only for ETF)</Option>
                            <Option value={"tot_return_index_gross_dvds"}>Total Return Gross Dividends</Option>
                        </Select>
                        <h3 style={{paddingBottom:5, paddingTop: 30}}>Hedging Ratios:</h3>
                        {inputBoxes}
                        <div style={{paddingTop: 30}}>
                            <p style={{paddingBottom:5}}>Start Date:</p>
                            <input value={betaRegressionSettings?.start_date} style={{...styles.input, padding: 10, fontSize: 12, maxWidth: 200}} type="date" onChange={e => {
                                setBetaRegressionSettings({...betaRegressionSettings, start_date: e?.target?.value})
                            }}/>
                            <p style={{paddingBottom:5}}>End Date:</p>
                            <input value={betaRegressionSettings?.end_date} style={{...styles.input, padding: 10, fontSize: 12, maxWidth: 200}} type="date" onChange={e => {
                                setBetaRegressionSettings({...betaRegressionSettings, end_date: e?.target?.value})
                            }
                            }/>
                        </div>
                        <div style={{paddingTop:50}}>
                            <button className='hover-update' style={{padding: 10, minWidth: 260}} onClick={() => {
                                async function getCurHedge() {
                                    if (betaRegressionSettings?.fund === 'PNL') {
                                        alert("Unable to load current hedge for PNL. Hedging instruments not supported.")
                                    } else {
                                        setIsLoading(true)
                                        await api(`fund-data/current-hedge?ticker=${betaRegressionSettings?.fund}`).then(res => res.json()).then(data => {
                                            setHedgeDataLoaded(false)
                                            setBetaRegressionSettings({...betaRegressionSettings, 
                                                hedging_instruments: data?.data?.hedging_instruments, 
                                                hedging_ratios: data?.data?.hedging_ratios, 
                                                beta_actual: data?.data?.beta_exposure,
                                                lower_bounds: data?.data?.hedging_instruments?.map((instrument, i) => 0.0),
                                                upper_bounds: data?.data?.hedging_instruments?.map((instrument, i) => 1.0),
                                                beta_actual: data?.data?.beta_exposure,
                                            });
                                            setHedgeDataLoaded(true)
                                            setIsLoading(false)
                                        });
                                    }  
                                }
                                getCurHedge()
                                setVisualizationTitle(betaRegressionSettings?.fund)
                            }}>Reset Settings</button>
                        </div>
                        <div style={{paddingTop: 10}}>
                        <button className='hover-update' style={{padding: 10, minWidth: 260}} onClick={() => {
                            if (sum(betaRegressionSettings?.hedging_ratios) > 1.01 | sum(betaRegressionSettings?.hedging_ratios < 0.0)) {
                                alert('The hedging ratios should sum to 1. Please input valid hedging ratios.');
                            } else {
                                setIsLoading(true);
                                async function getBetaRegression() {
                                    setBetaRegression({});
                                    await betaRegressionApi("regression", "POST", {
                                    fund: betaRegressionSettings?.fund, 
                                    hedging_instruments: betaRegressionSettings?.hedging_instruments.map(str => str.toLowerCase()),
                                    hedging_ratios: betaRegressionSettings?.hedging_ratios,
                                    start_date: betaRegressionSettings?.start_date,
                                    end_date: betaRegressionSettings?.end_date,
                                    type: hedgingInstrumentsDataType
                                    })
                                    .then(response => response.json())
                                    .then(data => {
                                        setBetaRegression(data);
                                        setIsLoading(false)
                                    });
                                }
                                getBetaRegression()
                                setVisualizationTitle(betaRegressionSettings?.fund)
                            }
                        }}>Run Beta Regression</button>
                        </div>
                        <div style={{paddingTop:10}}>
                            <button className='hover-update' style={{padding: 10, minWidth: 260}} onClick={() => {
                                if (dateDiff(new Date(betaRegressionSettings?.start_date), new Date(betaRegressionSettings?.end_date)) > 90) {
                                    alert("Optimization only allowed for maximum 90 days. Please run optimization on shorter period.")
                                } else if (betaRegressionSettings?.hedging_instruments?.length > 3) {
                                    alert("Optimization only allowed for 3 hedging instruments at a time. Please run optimization on fewer instruments.")
                                } else {
                                    setIsLoading(true)
                                    async function getOptimizationResults() {
                                        await betaRegressionApi("optimize", "POST", {
                                            fund: betaRegressionSettings?.fund, 
                                            hedging_instruments: betaRegressionSettings?.hedging_instruments?.map(str => str.toLowerCase()),
                                            start_date: betaRegressionSettings?.start_date,
                                            end_date: betaRegressionSettings?.end_date,
                                            bounds: betaRegressionSettings?.lower_bounds?.map((lower_bound, i) => [lower_bound, betaRegressionSettings?.upper_bounds[i]]),
                                            type: hedgingInstrumentsDataType
                                        })
                                        .then(response => response.json())
                                        .then(data => {
                                            setHedgeDataLoaded(false)
                                            setBetaRegressionSettings({...betaRegressionSettings, hedging_ratios: data?.hedging_ratios});
                                            setHedgeDataLoaded(true)
                                            setIsLoading(false)
                                        });
                                    }
                                    getOptimizationResults()
                                }
                            }}>Optimize Hedging Instrument Mix</button>
                        </div>
                        <div style={{paddingTop:10}}>
                            <button className='hover-update' style={{padding: 10, minWidth: 260}} onClick={() => {
                                async function postBetaRegressionResults() {
                                    await api(`fund-data/beta-regression-results`, "POST", {
                                        "fund": [betaRegressionSettings?.fund],
                                        "hedging_instruments": betaRegressionSettings?.hedging_instruments,
                                        "hedging_ratios": betaRegressionSettings?.hedging_ratios?.map(hedging_ratio => hedging_ratio.toString()),
                                        "beta": [betaRegression?.beta.toString()],
                                        "r_squared": [betaRegression?.r_squared.toString()],
                                        "start_date": [betaRegressionSettings?.start_date],
                                        "end_date": [betaRegressionSettings?.end_date],
                                    }).then(res => res.json()).then(data => {
                                        if (data?.status === 'success') {
                                            alert("Beta regression results successfully saved to database.")
                                        } else {
                                            alert("Unable to save beta regression results to database.")
                                        }
                                    });
                                }
                                postBetaRegressionResults()
                            }}>Save Beta Regression Results</button>
                        </div>
                    </div>
                </Sidebar>
                <div style={{justifyContent: "space-around", margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"left", paddingBottom:20, border:"1px solid white"}}>
                {Object.keys(betaRegression).length !== 0 && (
                    <table border={1} style={{...styles.table}}>
                        <thead>
                            <tr>
                            <th>Beta (Analysis)</th>
                            <th>Beta (Actual)</th>
                            <th>R-Squared</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td style={{textAlign: "center", minWidth: 100}} key={"beta"}>{betaRegression?.beta?.toFixed(3)}</td>
                            <td style={{textAlign: "center", minWidth: 100}} key={"beta_actual"}>{betaRegressionSettings?.beta_actual?.toFixed(3)}</td>
                            <td style={{textAlign: "center", minWidth: 100}} key={"r_squared"}>{betaRegression?.r_squared?.toFixed(3)}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
                </div>
            </main>
        );
    } else if (page === 'portfolio_analysis') {
        return (
            <main style={{height: "100%", boxSizing: "inherit"}}>
                {isLoading && (
                    <div style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <LoadingSpinner style={{ margin: 0, padding: 0 }}/>
                        <button style={{padding: 15, marginTop: 0, width: "5%"}} onClick={() => {
                        setIsLoading(false);
                        }}>Cancel</button>
                    </div>)}
                {/* <div style={{justifyContent: "left", float: "left", display: "flex", paddingTop: 10, flex: 10}}> */}
                <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <h3>Portfolio Securities Intersection Matrix:</h3>
                        <table border={1} style={{...classes.table, fontSize: "0.8rem", marginBottom: 25}}>
                            <thead style={classes.header}>
                                <tr>
                                <th></th>
                                {curPortfolioAnalysisFunds?.map((fund) => {
                                    return (
                                    <th key={fund}>{fund}</th>
                                )})}
                                </tr>
                            </thead>
                            <tbody>
                                {curPortfolioAnalysisFunds?.map((fund, i) => {
                                    return (
                                        <tr key={fund}>
                                            <th style={classes.header}>{fund}</th>
                                            {curPortfolioAnalysisFunds?.map((fund, j) => (
                                                <th>{portfolioAnalysisData?.intersection_matrix?.[i]?.[j]}</th>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <h3>Portfolio Securities % NAV Intersection Matrix:</h3>
                        <table border={1} style={{...classes.table, fontSize: "0.8rem", marginBottom: 25}}>
                            <thead style={classes.header}>
                                <tr>
                                <th></th>
                                {curPortfolioAnalysisFunds?.map((fund) => {
                                    return (
                                    <th key={fund}>{fund}</th>
                                )})}
                                </tr>
                            </thead>
                            <tbody>
                                {curPortfolioAnalysisFunds?.map((fund, i) => {
                                    return (
                                        <tr key={fund}>
                                            <th style={classes.header}>{fund}</th>
                                            {curPortfolioAnalysisFunds?.map((fund, j) => (
                                                <th>{portfolioAnalysisData?.intersection_percentage_nav_matrix?.[i]?.[j]?.toFixed(2)}</th>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <h3>NAV Correlation Matrix:</h3>
                        <table border={1} style={{...classes.table, fontSize: "0.8rem", marginBottom: 25}}>
                            <thead style={classes.header}>
                                <tr>
                                <th></th>
                                {curPortfolioAnalysisFunds?.map((fund) => {
                                    return (
                                    <th key={fund}>{fund}</th>
                                )})}
                                </tr>
                            </thead>
                            <tbody>
                                {curPortfolioAnalysisFunds?.map((fund, i) => {
                                    return (
                                        <tr key={fund}>
                                            <th style={classes.header}>{fund}</th>
                                            {curPortfolioAnalysisFunds?.map((fund, j) => (
                                                <th>{portfolioAnalysisData?.nav_correlation_matrix?.[i]?.[j]?.toFixed(2)}</th>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <h3>Prem/Disc Correlation Matrix:</h3>
                        <table border={1} style={{...classes.table, fontSize: "0.8rem", marginBottom: 25}}>
                            <thead style={classes.header}>
                                <tr>
                                <th></th>
                                {curPortfolioAnalysisFunds?.map((fund) => {
                                    return (
                                    <th key={fund}>{fund}</th>
                                )})}
                                </tr>
                            </thead>
                            <tbody>
                                {curPortfolioAnalysisFunds?.map((fund, i) => {
                                    return (
                                        <tr key={fund}>
                                            <th style={classes.header}>{fund}</th>
                                            {curPortfolioAnalysisFunds?.map((fund, j) => (
                                                <th>{portfolioAnalysisData?.prem_disc_correlation_matrix?.[i]?.[j]?.toFixed(2)}</th>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Sidebar>
                    <div style={{justifyContent: "space-around", minWidth: 300, margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:50}}>
                        <h3 style={{paddingBottom:5}}>Pages:</h3>
                        <Select
                            style={{ width: 200, paddingBottom:15}}
                            placeholder="Select a page"
                            optionFilterProp="page"
                            defaultValue="single_regression"
                            value={page}
                            onChange={(value) => {
                                setPage(value)
                            }}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                                <Option value="single_regression">Single Regression</Option>
                                <Option value="results">Regression Results</Option>
                                <Option value="portfolio_analysis">Portfolio Correlation Analysis</Option>
                                <Option value="portfolio_optimization">Portfolio Optimization</Option>
                        </Select>
                        <p>Funds:</p>
                        <select value={portfolioAnalysisSettings?.funds?.length == 0 ? ["PCT", "ATT"]:portfolioAnalysisSettings?.funds} multiple
                        style={{width: 400, height: 400, padding: 5, alignSelf: "flex-end", color: "black"}}
                        onChange={e => {
                            e.persist();
                            var funds = []
                            for(let i = 0; i < e.target.options.length; i++) if(e.target.options[i].selected) funds.push(e.target.options[i].value)
                            if(funds.length > 0) setPortfolioAnalysisSettings({...portfolioAnalysisSettings, funds:funds})
                            }}>
                            {funds && funds?.map(fund => (<option selected>{fund}</option>))}
                        </select>
                        <div style={{paddingTop: 30}}>
                            <p style={{paddingBottom:5}}>Start Date:</p>
                            <input value={portfolioAnalysisSettings?.start_date} style={{...styles.input, padding: 10, fontSize: 12, maxWidth: 200}} type="date" onChange={e => {
                                setPortfolioAnalysisSettings({...portfolioAnalysisSettings, start_date: e?.target?.value})
                            }}/>
                            <p style={{paddingBottom:5}}>End Date:</p>
                            <input value={portfolioAnalysisSettings?.end_date} style={{...styles.input, padding: 10, fontSize: 12, maxWidth: 200}} type="date" onChange={e => {
                                setPortfolioAnalysisSettings({...portfolioAnalysisSettings, end_date: e?.target?.value})
                            }
                            }/>
                        </div>
                        <p style={{paddingTop: 30}}>NAV Type:</p>
                        <Select
                            style={{ width: 200, paddingBottom:15, paddingTop: 5}}
                            placeholder="Select NAV Type"
                            optionFilterProp="nav_type"
                            defaultValue="official"
                            value={portfolioAnalysisSettings?.nav_type}
                            onChange={(value) => {
                                setPortfolioAnalysisSettings({...portfolioAnalysisSettings, nav_type: value})
                            }}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                                <Option value="official">Official NAV</Option>
                                <Option value="uk_close">UK Close NAV</Option>
                        </Select>
                        <div style={{paddingTop:10}}>
                            <button className='hover-update' style={{padding: 10, minWidth: 150, marginBottom: 10}} onClick={() => {
                                async function getPortfolioAnalysis() {
                                    await portfolioAnalysisApi("analysis", "POST", {
                                        funds: portfolioAnalysisSettings?.funds, 
                                        start_date: portfolioAnalysisSettings?.start_date,
                                        end_date: portfolioAnalysisSettings?.end_date,
                                        nav_type: portfolioAnalysisSettings?.nav_type
                                    })
                                    .then(response => response.json())
                                    .then(data => {
                                        setPortfolioAnalysisData(data);
                                        setIsLoading(false)
                                    });
                                }
                                if (portfolioAnalysisSettings?.funds.length > 1) {
                                    setIsLoading(true)
                                    getPortfolioAnalysis()
                                    setCurPortfolioAnalysisFunds(portfolioAnalysisSettings?.funds)
                                } else {
                                    alert('Please select more than one fund to run analysis.')
                                }
                            }}>Run Analysis</button>
                        </div>
                    </div>
                </Sidebar>
            </main>
        )
    } else if (page === 'portfolio_optimization') {
        return (
            <main style={{height: "100%", boxSizing: "inherit"}}>
                {isLoading && (
                    <div style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <LoadingSpinner style={{ margin: 0, padding: 0 }}/>
                        <button style={{padding: 15, marginTop: 0, width: "5%"}} onClick={() => {
                            setIsLoading(false);
                        }}>Cancel</button>
                    </div>)}
                {/* <div style={{justifyContent: "left", float: "left", display: "flex", paddingTop: 10, flex: 10}}> */}
                <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                    <table border={1} style={{...classes.table, fontSize: "0.8rem", marginBottom: 25}}>
                        <thead style={classes.header}>
                            <tr>
                                <th></th>
                                <th style={classes.header}>Current Weight</th>
                                <th style={classes.header}>Target Weight</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(portfolioOptimizationData?.weights || {}).sort((a,b) => fundOrdering[a] - fundOrdering[b])?.map((ticker, i) => {
                                let rows = [];
                                const tickersArray = Object.keys(portfolioOptimizationData?.weights || {}).sort((a,b) => fundOrdering[a] - fundOrdering[b]);
                                let priorTicker;
                                if (i !== 0) {
                                    priorTicker = tickersArray[i-1]
                                }

                                if (!priorTicker) {
                                    rows.push(
                                        <tr style={{height: 25, fontSize: 10}}>
                                            <td colSpan={11} style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: "#676767"}}>
                                                {Object.keys(fundSectors).find(key => fundSectors[key].includes(ticker))}
                                            </td>
                                        </tr>
                                    )
                                } else if (Object.keys(fundSectors).find(key => fundSectors[key].includes(priorTicker)) !== Object.keys(fundSectors).find(key => fundSectors[key].includes(ticker))) {
                                    rows.push(
                                        <tr style={{height: 25, fontSize: 10}}>
                                            <td colSpan={11} style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: "#676767"}}>
                                                {Object.keys(fundSectors).find(key => fundSectors[key].includes(ticker))}
                                            </td>
                                        </tr>
                                    )
                                }

                                rows.push(
                                    <tr key={ticker}>
                                        <th style={classes.header}>{ticker}</th>
                                        <td style={{
                                            textAlign: 'right',
                                            paddingRight: 20
                                        }}>{`${((portfolioOptimizationData?.cur_port?.weight?.[Object.keys(portfolioOptimizationData?.cur_port?.epic)?.find(key => portfolioOptimizationData?.cur_port?.epic?.[key] === ticker)] || 0)*100)?.toFixed(2)}%`}</td>
                                        <td style={{
                                            textAlign: 'right',
                                            paddingRight: 20
                                        }}>{`${(portfolioOptimizationData?.weights?.[ticker]*100)?.toFixed(2)}%`}</td>
                                    </tr>
                                );

                                return rows;
                            })}
                        </tbody>
                    </table>
                    <table border={1} style={{...classes.table, fontSize: "0.8rem", marginLeft: 25, marginBottom: 25}}>
                        <thead style={classes.header}>
                            <tr>
                                <th></th>
                                <th style={classes.header}>Action</th>
                                <th style={classes.header}>Weight Change</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(portfolioOptimizationData?.weights || {})?.sort((a, b) => {
                                const weightDifferenceA = portfolioOptimizationData?.weights?.[a]-(portfolioOptimizationData?.cur_port?.weight?.[Object.keys(portfolioOptimizationData?.cur_port?.epic)?.find(key => portfolioOptimizationData?.cur_port?.epic?.[key] === a)] || 0);
                                const weightDifferenceB = portfolioOptimizationData?.weights?.[b]-(portfolioOptimizationData?.cur_port?.weight?.[Object.keys(portfolioOptimizationData?.cur_port?.epic)?.find(key => portfolioOptimizationData?.cur_port?.epic?.[key] === b)] || 0);

                                // Compare by instrument type.
                                if (weightDifferenceA > weightDifferenceB) return -1;
                                if (weightDifferenceA < weightDifferenceB) return 1;

                                return 0;
                            })?.map((ticker) => {
                                return (
                                    <tr key={ticker}>
                                        <th style={classes.header}>{ticker}</th>
                                        <td style={{
                                            textAlign: 'center',
                                            backgroundColor: 
                                                portfolioOptimizationData?.weights?.[ticker]-(portfolioOptimizationData?.cur_port?.weight?.[Object.keys(portfolioOptimizationData?.cur_port?.epic)?.find(key => portfolioOptimizationData?.cur_port?.epic?.[key] === ticker)] || 0) < 0 ? "red" : 
                                                portfolioOptimizationData?.weights?.[ticker]-(portfolioOptimizationData?.cur_port?.weight?.[Object.keys(portfolioOptimizationData?.cur_port?.epic)?.find(key => portfolioOptimizationData?.cur_port?.epic?.[key] === ticker)] || 0) > 0 ? "green" : "yellow"
                                        }}>{
                                            portfolioOptimizationData?.weights?.[ticker]-(portfolioOptimizationData?.cur_port?.weight?.[Object.keys(portfolioOptimizationData?.cur_port?.epic)?.find(key => portfolioOptimizationData?.cur_port?.epic?.[key] === ticker)] || 0) < 0 ? "SELL" :
                                            portfolioOptimizationData?.weights?.[ticker]-(portfolioOptimizationData?.cur_port?.weight?.[Object.keys(portfolioOptimizationData?.cur_port?.epic)?.find(key => portfolioOptimizationData?.cur_port?.epic?.[key] === ticker)] || 0) > 0 ? "BUY" :
                                            "HOLD"
                                        }</td>
                                        <td style={{
                                            textAlign: 'right',
                                            paddingRight: 20
                                        }}>{`${((portfolioOptimizationData?.weights?.[ticker]-(portfolioOptimizationData?.cur_port?.weight?.[Object.keys(portfolioOptimizationData?.cur_port?.epic)?.find(key => portfolioOptimizationData?.cur_port?.epic?.[key] === ticker)] || 0))*100)?.toFixed(2)}%`}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {Object.keys(portfolioOptimizationData?.res || {})?.includes("cvar") ? (
                        <table border={1} style={{...classes.table, minWidth: 200, maxHeight: 50, marginLeft: 25, fontSize: "0.8rem", marginBottom: 25}}>
                            <thead style={classes.header}>
                                <tr>
                                    <th style={classes.header}>Expected Return (1 Day)</th>
                                    <th style={classes.header}>CVaR (1 Day)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{
                                        textAlign: 'right',
                                        paddingRight: 10
                                    }}>{`${(portfolioOptimizationData?.res?.expected_return*100)?.toFixed(2) || 0}%`}</td>
                                    <td style={{
                                        textAlign: 'right',
                                        paddingRight: 10
                                    }}>{`${(portfolioOptimizationData?.res?.cvar*100)?.toFixed(2) || 0}%`}</td>
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                        <table border={1} style={{...classes.table, minWidth: 200, maxHeight: 50, marginLeft: 25, fontSize: "0.8rem", marginBottom: 25}}>
                            <thead style={classes.header}>
                                <tr>
                                    <th style={classes.header}>Current Volatility (1 Day)</th>
                                    <th style={classes.header}>Expected Volatility (1 Day)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{
                                        textAlign: 'right',
                                        paddingRight: 10
                                    }}>{`${(portfolioOptimizationData?.res?.current_vol*100)?.toFixed(2) || 0}%`}</td>
                                    <td style={{
                                        textAlign: 'right',
                                        paddingRight: 10
                                    }}>{`${(portfolioOptimizationData?.res?.expected_vol*100)?.toFixed(2) || 0}%`}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
                <Sidebar page={"optimization"}>
                    <div style={{justifyContent: "space-around", minWidth: 300, margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:50}}>
                        <h3 style={{paddingBottom:5}}>Pages:</h3>
                        <Select
                            style={{ width: 200, paddingBottom:15}}
                            placeholder="Select a page"
                            optionFilterProp="page"
                            defaultValue="single_regression"
                            value={page}
                            onChange={(value) => {
                                setPage(value)
                            }}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                                <Option value="single_regression">Single Regression</Option>
                                <Option value="results">Regression Results</Option>
                                <Option value="portfolio_analysis">Portfolio Correlation Analysis</Option>
                                <Option value="portfolio_optimization">Portfolio Optimization</Option>
                        </Select>
                        <p style={{paddingTop: 30}}>Optimization Objective:</p>
                        <Select
                            style={{ width: 200, paddingBottom:15, paddingTop: 5}}
                            placeholder="Select Optimization Objective"
                            optionFilterProp="objective"
                            defaultValue="min_cvar_rebalance"
                            value={portfolioOptimizationSettings?.objective}
                            onChange={(value) => {
                                setPortfolioOptimizationSettings({...portfolioOptimizationSettings, objective: value})
                            }}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                                <Option value="min_cvar">Minimize CVaR</Option>
                                <Option value="min_cvar_rebalance">Minimize CVaR (Rebalance Existing Portfolio)</Option>
                                <Option value="min_volatility">Minimize Volatility</Option>
                                <Option value="min_volatility_rebalance">Minimize Volatility (Rebalance Existing Portfolio)</Option>
                        </Select>
                        <div style={{paddingTop: 30}}>
                            <p style={{alignSelf: "flex-end"}}>Funds:</p>
                            <select value={portfolioOptimizationSettings?.funds} multiple
                            style={{width: 180, height: 400, padding: 5, alignSelf: "flex-end", color: "black"}}
                            onChange={e => {
                                e.persist();
                                var funds = []
                                for(let i = 0; i < e.target.options.length; i++) if(e.target.options[i].selected) funds.push(e.target.options[i].value)
                                if(funds.length > 0) setPortfolioOptimizationSettings(prevState => ({...prevState, funds:funds}))
                            }}>
                            {funds && funds?.map(fund => (<option key={fund} selected>{fund}</option>))}
                            </select>
                        </div>
                        <div style={{paddingTop: 30}}>
                            <p style={{alignSelf: "flex-end"}}>Sector:</p>
                            <select value={portfolioOptimizationSettings?.sector} 
                            style={{width: 180, padding: 5, alignSelf: "flex-end", color: "black"}}
                            onChange={e => {
                                e.persist();
                                setPortfolioOptimizationSettings(prevState => ({...prevState, sector: e?.target?.value, funds: fundSectors?.[e?.target?.value]}))
                            }}>
                            {fundSectors && Object.keys(fundSectors)?.map(sector => (<option key={sector}>{sector}</option>))}
                            </select>
                        </div>
                        <div style={{paddingTop: 30}}>
                            <p style={{paddingBottom:5}}>Start Date:</p>
                            <input value={portfolioOptimizationSettings?.start_date} style={{...styles.input, padding: 10, fontSize: 12, maxWidth: 200}} type="date" onChange={e => {
                                setPortfolioOptimizationSettings({...portfolioOptimizationSettings, start_date: e?.target?.value})
                            }}/>
                            <p style={{paddingBottom:5}}>End Date:</p>
                            <input value={portfolioOptimizationSettings?.end_date} style={{...styles.input, padding: 10, fontSize: 12, maxWidth: 200}} type="date" onChange={e => {
                                setPortfolioOptimizationSettings({...portfolioOptimizationSettings, end_date: e?.target?.value})
                            }
                            }/>
                        </div>
                        <div style={{paddingTop: 30}}>
                            <label>Lower Bound:</label><br/>
                            <Input style={{maxWidth:100}}
                                type="number"
                                step="0.01"
                                value={portfolioOptimizationSettings?.lower_bound || ""}
                                onChange={(e) => {
                                    e.persist();
                                    setPortfolioOptimizationSettings(prevState => ({...prevState, lower_bound: e?.target?.value}));
                                }}
                            /><br/>
                            <label>Upper Bound:</label><br/>
                            <Input style={{maxWidth:100}}
                                type="number"
                                step="0.01"
                                value={portfolioOptimizationSettings?.upper_bound || ""}
                                onChange={(e) => {
                                    e.persist();
                                    setPortfolioOptimizationSettings(prevState => ({...prevState, upper_bound: e?.target?.value}));
                                }}
                            />
                        </div>
                        <div style={{paddingTop: 30}}>
                            <label>Weights sum:</label><br/>
                            <Input style={{maxWidth:100}}
                                type="number"
                                step="0.01"
                                value={portfolioOptimizationSettings?.weights_sum || ""}
                                onChange={(e) => {
                                    e.persist();
                                    setPortfolioOptimizationSettings(prevState => ({...prevState, weights_sum: e?.target?.value}));
                                }}
                            />
                        </div>
                        <div style={{paddingTop: 30}}>
                            <label>Min. Number of Assets:</label><br/>
                            <Input style={{maxWidth:100}}
                                type="number"
                                step="1"
                                value={portfolioOptimizationSettings?.min_assets || ""}
                                onChange={(e) => {
                                    e.persist();
                                    setPortfolioOptimizationSettings(prevState => ({...prevState, min_assets: e?.target?.value}));
                                }}
                            />
                        </div>
                        <div style={{paddingTop: 30}}>
                            <label>Min. Weight</label><br/>
                            <small>(Only for Long only/Short only portfolios):</small><br/>
                            <Input style={{maxWidth:100}}
                                type="number"
                                step="0.0001"
                                value={portfolioOptimizationSettings?.min_weight || ""}
                                onChange={(e) => {
                                    e.persist();
                                    setPortfolioOptimizationSettings(prevState => ({...prevState, min_weight: e?.target?.value}));
                                }}
                            />
                        </div>
                        <div style={{paddingTop: 30}}>
                            <label>Weight Penalty Factor</label><br/>
                            <small>(Only for Rebalancing Objectives):</small><br/>
                            <Input style={{maxWidth:100}}
                                type="number"
                                step="0.0001"
                                value={portfolioOptimizationSettings?.eta || ""}
                                onChange={(e) => {
                                    e.persist();
                                    setPortfolioOptimizationSettings(prevState => ({...prevState, eta: e?.target?.value}));
                                }}
                            />
                        </div>
                        <div style={{paddingTop:30}}>
                            <button className='hover-update' style={{padding: 10, minWidth: 150, marginBottom: 10}} onClick={() => {
                                if (dateDiff(new Date(portfolioOptimizationSettings?.start_date), new Date(portfolioOptimizationSettings?.end_date)) > 365) {
                                    alert("Optimization only allowed for maximum 365 days. Please run optimization on shorter period.")
                                } else {
                                    setIsLoading(true);
                                    async function getPortfolioOptimization() {
                                        await portfolioOptimizationApi("optimize", "POST", {
                                            start_date: portfolioOptimizationSettings?.start_date,
                                            end_date: portfolioOptimizationSettings?.end_date,
                                            objective: portfolioOptimizationSettings?.objective,
                                            lower_bound: portfolioOptimizationSettings?.lower_bound,
                                            upper_bound: portfolioOptimizationSettings?.upper_bound,
                                            weights_sum: portfolioOptimizationSettings?.weights_sum,
                                            min_weight: portfolioOptimizationSettings?.min_weight,
                                            funds: portfolioOptimizationSettings?.funds,
                                            eta: portfolioOptimizationSettings?.eta,
                                            min_assets: portfolioOptimizationSettings?.min_assets
                                        })
                                        .then(response => response.json())
                                        .then(data => {
                                            setPortfolioOptimizationData(data);

                                            // Alert user if the earliest date used in optimization is greater than the user specified date.
                                            if (new Date(portfolioOptimizationSettings?.start_date) < new Date(data?.res?.earliest_date)) {
                                                alert(`Earliest date used in optimization: ${data?.res?.earliest_date} is greater than user specified date: ${portfolioOptimizationSettings?.start_date}.\n\nThis is due to data not being available historically for one or more tickers included in optimization.\n\nPlease remove these if it is wanted to run analysis for longer period.`)
                                            }
                                            setIsLoading(false);
                                        });
                                    }
                                    getPortfolioOptimization();
                                }
                            }}>Optimize</button>
                        </div>
                    </div>
                </Sidebar>
            </main>
        )
    }
}

const classes = {
    box: {
        backgroundColor: "white",
        position:"fixed",
        padding: 10,
        borderRadius: 25,
        zIndex: 999,
    },
    closing_icon: {
        position: "absolute", 
        right:12, 
        top: 12,
        cursor:"pointer",
    },
    table: {
        borderSpacing: 0,
        backgroundColor: "white",
        color: "rgba(1,1,1,1.0)",
        minWidth: 500
    },
    header: {
        backgroundColor: "rgb(32,56,100)",
        color: "white",
        padding: "3px 5px",
        textAlign: "center",
        borderBottom: "3px solid black",
    },
    cell: {
        backgroundColor: "white",
        padding: 5,
    }
}

export default Analysis;