import React from 'react';

const Sidebar = ({ children, page = "standard" }) => {
  return (
    <div className="flex" style={{float: 'right'}}>
      <aside className={page === "standard" ? "w-128 h-screen bg-gray-800 text-white p-4" : "w-128 h-full bg-gray-800 text-white p-4"}>
        <div className="text-lg font-bold mb-4">Settings</div>
        <nav>
          {children}
        </nav>
      </aside>
    </div>
  );
};

export default Sidebar;