import React, { useState, useEffect } from 'react';
import api from '../api/api';
import { styles } from '../global/styles';

const EntryProfileModal = ({spreadId, tickers, statArbEntryProfiles, setOpenStatArbEntryProfileModal}) => {

  const handleClose = () => {
    setOpenStatArbEntryProfileModal(false);
  };

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      width: "600px",
      height: "600px",
      backgroundColor: "#303030",
      zIndex: 9999,
      display: "flex",
      flexDirection: "column",
      border: "2px solid black"}}>

      {/* Close Button */}
      <div style={{position: "absolute", top: "5px", right: "5px", cursor: "pointer", color: "white", fontSize: "20px"}} onClick={handleClose}>
        &times;
      </div>

      <h2 style={{textAlign: "center", marginTop: 50, fontSize: 24, marginBottom: 15}}>{`Entry Profile (${tickers?.join("/")})`}</h2>
      <hr style={{
          border: '1px solid #000',
          width: '100%',
          marginBottom: 15
      }}/>

      <div style={{flex: 1, padding: 20, float: 'left', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', overflow: 'auto', marginBottom: 60}}>
        <table className="border border-solid border-gray-300/[.45]" style={{ ...styles.table.table, margin: '0 auto', border: '', padding: 0, width: 400 }}>
          <thead style={styles.table.header}>
            <tr style={{fontSize: 14}}>
            <th style={{
              border: 0,
              padding: 10
            }}>Step</th>
            <th style={{
              border: 0,
              padding: 10
            }}>Z-Score</th>
            </tr>
          </thead>
          <tbody>
            {statArbEntryProfiles?.[spreadId]?.map((zScore, i) => (
              <tr key={i} className="hover:bg-gray-700">
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "center",
                    marginRight: 5,
                    padding: 5
                  }}
                >{i+1}</td>
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "center",
                    marginRight: 5,
                    padding: 5
                  }}
                >{zScore?.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div> 
  );
};

export default EntryProfileModal;
