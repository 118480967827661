require("dotenv").config()

export default (endpoint, method, body) => {
    let url = `https://trading.tricap.dk:8002/api/${endpoint}`;
    let request_headers = {
        'Content-Type': 'application/json',
        "token": "i8jOpZdVfmMJfXaQRXsgbu4mvZ9vLea8PmZ4kUc5ZKyC76DMRPlM0WBVKPeTBueJVXDmysmt9p4D0zOt6eFAPcGoDqyHImvckKRdgTumkYYnufo4wFsDVQYL09CWTX1j"
    }
    let request_options = {
        method: method?.toUpperCase(),
        mode: "cors",
        headers: request_headers
    }
    if(body) request_options.body = JSON.stringify(body)
    return fetch(url, request_options)
}