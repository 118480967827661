import logo from '../assets/TriCap_Final_300.png';
import '../App.css';
import { NavLink } from "react-router-dom";
import React, { useContext, useState, useEffect } from 'react';
import Context from '../store/context';
import SettingsMenu from './settings-menu';
import { useAuthState } from '../store/auth';

const HeadNav = (props) => {
  const {user, setUser, notifications, setNotifications, globalState, setGlobalState} = useContext(Context)
  const [filter, setFilter] = useState()

  const {
    apiKey,
    setApiKey
  } = useAuthState();

  // Get api key for internal api.
  useEffect(() => {
    fetch('config.json')
    .then(response => response.json())
    .then(data => {
      setApiKey(data?.apiKey)
    });
  }, [])

  return (
      <header className="App-header">
      <NavLink to="/">
        <div style={{display: "flex",alignItems: "center"}} className="logo" alt="logo">
          <img src={logo} alt="TriCap logo" className="logo" style={{width: "100px", height: "auto", padding: "10px"}}/>
          <span style={{
            color: "#f68b1f", 
            fontSize: "24px", marginTop: 3, fontFamily: "Arial, sans-serif"}}>Analytics</span>
        </div>
      </NavLink>
      <nav>
        <ul className="nav-header">
          <li>
            <NavLink className="navlink" to="/reports">Reports</NavLink>
          </li>
          <li>
            <NavLink className="navlink" to="/graphs">Graphs</NavLink>
          </li>
          <li>
            <NavLink className="navlink" to="/analysis">Analysis</NavLink>
          </li>
          <li>
            <NavLink className="navlink" to="/monitor">Monitor</NavLink>
          </li>
          <li>
            <NavLink className="navlink" to="/indicators">Indicators</NavLink>
          </li>
        </ul>
      </nav>
      <div style={{padding: 0, display: "flex"}}>
        <span style={{marginTop: 5, display: "flex"}}>
          <span style={{marginRight: 8}}><SettingsMenu/></span>
        </span>
        <div className="nav_btn">
          <button onClick={() => setUser(null)}>Log out</button>
        </div>
      </div>
    </header>
  )
}

const local_styles = {
  notification_container: {
    backgroundColor: "rgba(141,191,255,0.9)", 
    border: "2px solid #226699",
    position: "absolute", 
    padding: 0,
    top: 40,
    minWidth: 255,
    right: "15%",
    maxHeight: "60vh", 
    overflowY: "scroll"
  },
  notification_header: {
    fontSize: 10,
    backgroundColor: "#226699",
    textAlign: "right"
  },
  notification_counter: {
    backgroundColor: "red", 
    // position: "absolute",
    fontSize: 10,
    fontWeight: "bold",
    padding: 2,
    margin: "0 5px 15px -12px",
    borderRadius: "50%",
  }
}

export default HeadNav;